import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { Toaster } from '@waffle/ui-web';
// Styles for Tailwind theme
import '@waffle/ui-web/tailwind.css';

import './css-reset.css';
import { routes } from './routes';

const container = document.getElementById('root');
const root = createRoot(container!);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

const router = createBrowserRouter(routes);

root.render(
  <React.StrictMode>
    {/*Inject Query Client for React-query*/}
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>

    {/* Provider for Toasts */}
    <Toaster closeButton={true} />
  </React.StrictMode>,
);
