import _ from 'lodash';
import Lottie from 'lottie-react';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Listings, Rewards } from '@waffle/common/src/models';
import { RewardsCard } from '@waffle/components-web';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogBody,
  DialogContent,
  HBox,
  Pressable,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Text,
  WaffleSuspenseBoundary,
} from '@waffle/ui-web';
import {
  ArrowRightIcon,
  PercentIcon,
  ShoppingBasketIcon,
  StoreIcon,
  TrendingUpIcon,
  WalletIcon,
  ZapIcon,
} from '@waffle/ui-web/icons';

import LottieEmptyIssuedRewards from '../../../assets/lottie/lf20_wrbylkbm.json';
import {
  useAssertRewardsMembership,
  useIssuedRewardsQuery,
  useRewardsListingsQuery,
  useRewardsProgrammeQuery,
} from '../../../services/rewardsService';
import { useSubdomainSellerQuery } from '../../../services/sellersService';

export const RewardsPage = () => {
  const { data: seller } = useSubdomainSellerQuery();
  const { data: rewardsProgramme } = useRewardsProgrammeQuery();
  // IMPORTANT! RewardsMembership is not created automatically for now
  // TODO: Every Rewards related query should assert the RewardsMembership in the future
  const { data: rewardsMembership } = useAssertRewardsMembership();

  const [isRewardsPerksDialogOpen, setRewardsPerksDialogOpen] = useState(false);

  return (
    <>
      <Box className={'items-center py-8'}>
        <Box className={'w-full max-w-[600px] gap-8'}>
          <Box className={'w-full items-center justify-center'}>
            <Box className={'w-full gap-4 sm:max-w-[384px]'}>
              <Text variant={'h1'}>Rewards</Text>
              {/* Card */}
              <RewardsCard
                seller={seller}
                rewardsProgramme={rewardsProgramme}
                rewardsMembership={rewardsMembership}
              />

              {rewardsProgramme.isPointsExpiryEnabled === true &&
                rewardsProgramme.pointsExpiryWindowMonths !== null &&
                rewardsMembership.currentPoints > 0 && (
                  <Box>
                    <Text variant={'label'}>
                      {rewardsMembership.currentPoints}{' '}
                      {rewardsProgramme.pointName} will expire by{' '}
                      {DateTime.fromISO(rewardsMembership.lastActivityDatetime)
                        .plus({
                          months: rewardsProgramme.pointsExpiryWindowMonths,
                        })
                        .toFormat('MMMM dd, yyyy')}
                    </Text>
                    <Text variant={'muted'}>
                      Make any transaction before that date to keep your{' '}
                      {rewardsProgramme.pointName}.
                    </Text>
                  </Box>
                )}

              <HBox className={'gap-2'}>
                <Button
                  className={'flex-1'}
                  variant={'secondary'}
                  onPress={() => setRewardsPerksDialogOpen(true)}>
                  <ZapIcon />
                  View Perks
                </Button>
                <Link to={'/rewards/activity'} className={'flex-1'}>
                  <Button variant={'secondary'}>
                    <TrendingUpIcon />
                    View Rewards Activity
                  </Button>
                </Link>
              </HBox>
            </Box>
          </Box>
          <Card className={'rounded-3xl border-none'}>
            <CardContent>
              <Tabs defaultValue="inventory">
                <TabsList className="grid w-full grid-cols-2">
                  <TabsTrigger value="inventory" className={'gap-2'}>
                    <WalletIcon className={'size-4'} />
                    My Rewards
                  </TabsTrigger>
                  <TabsTrigger value="shop" className={'gap-2'}>
                    <StoreIcon className={'size-4'} />
                    Rewards Shop
                  </TabsTrigger>
                </TabsList>

                <TabsContent value="inventory">
                  <WaffleSuspenseBoundary>
                    <RewardsInventoryTab />
                  </WaffleSuspenseBoundary>
                </TabsContent>
                <TabsContent value="shop">
                  <WaffleSuspenseBoundary>
                    <RewardsShopTab />
                  </WaffleSuspenseBoundary>
                </TabsContent>
              </Tabs>
            </CardContent>
          </Card>
        </Box>
      </Box>
      {isRewardsPerksDialogOpen && (
        <RewardsPerksDialog onClose={() => setRewardsPerksDialogOpen(false)} />
      )}
    </>
  );
};

const RewardsInventoryTab = () => {
  const navigate = useNavigate();
  const { data: issuedRewards } = useIssuedRewardsQuery();

  return (
    <>
      <Box className={'gap-4'}>
        {issuedRewards.length !== 0 ? (
          _(issuedRewards)
            .orderBy((issuedReward) => issuedReward.issuedAt, ['asc'])
            .value()
            .map((issuedReward: Rewards.IssuedReward, index: number) => {
              return (
                <Pressable
                  key={issuedReward.id}
                  onPress={() =>
                    navigate(`/rewards/inventory/${issuedReward.id}`)
                  }
                  className={
                    'h-[100px] flex-row items-start gap-4 overflow-hidden rounded-lg bg-white p-2 enabled:hover:bg-gray-50'
                  }>
                  {/* Left side -- image / icon */}
                  <Box
                    className={
                      'aspect-square h-full items-center justify-center rounded-lg bg-gray-100'
                    }>
                    {issuedReward.listingSnapshot.rewardsListingType ===
                    Rewards.ListingType.ITEM ? (
                      <ShoppingBasketIcon className={'text-gray-500'} />
                    ) : (
                      <PercentIcon className={'text-gray-500'} />
                    )}
                  </Box>
                  {/* Right side -- Info section */}
                  <HBox className={'h-full flex-1 justify-between gap-2'}>
                    <Box className={'h-full w-full'}>
                      <Text className={'line-clamp-1 font-semibold'}>
                        {issuedReward.listingSnapshot.name}
                        {issuedReward.listingSnapshot.rewardsListingType ===
                          Rewards.ListingType.ITEM && (
                          <>
                            {' '}
                            {issuedReward.listingSnapshot.item
                              .itemVariationName === 'Default'
                              ? ''
                              : `(${issuedReward.listingSnapshot.item.itemVariationName})`}
                          </>
                        )}
                      </Text>
                      <Text variant={'muted'} className={'line-clamp-1'}>
                        {issuedReward.listingSnapshot.description}
                      </Text>
                      {!!issuedReward.expiresAt && (
                        <Text variant={'muted'} className={'line-clamp-1'}>
                          Expires on{' '}
                          {DateTime.fromISO(
                            issuedReward.expiresAt,
                          ).toLocaleString(DateTime.DATE_MED)}
                        </Text>
                      )}
                    </Box>
                    <Box>
                      <ArrowRightIcon className={'size-5'} />
                    </Box>
                  </HBox>
                </Pressable>
              );
            })
        ) : (
          <Box className={'items-center justify-center'}>
            <Box
              className={
                'max-w-[300px] items-center justify-center text-center'
              }>
              <Lottie
                animationData={LottieEmptyIssuedRewards}
                loop={true}
                autoplay={true}
              />
              <Text variant={'muted'}>
                You currently have no rewards, check out the Rewards Shop!
              </Text>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

const RewardsShopTab = () => {
  const navigate = useNavigate();

  const { data: rewardsProgramme } = useRewardsProgrammeQuery();
  const { data: rewardsMembership } = useAssertRewardsMembership();
  const { data: rewardsListings } = useRewardsListingsQuery();

  return (
    <>
      <Box className={'gap-4'}>
        {rewardsListings.length !== 0 ? (
          _.sortBy(
            rewardsListings,
            (rewardsListing) => rewardsListing.pointsRequired,
          ).map((rewardsListing: Rewards.RewardsListing, index: number) => {
            return (
              <Pressable
                key={rewardsListing.id}
                onPress={() => {
                  navigate(`/rewards/shop/${rewardsListing.id}`);
                }}
                className={
                  'h-[100px] flex-row items-start gap-4 overflow-hidden rounded-lg bg-white p-2 enabled:hover:bg-gray-50'
                }
                isDisabled={
                  rewardsMembership.currentPoints <
                  rewardsListing.pointsRequired
                }>
                {/* Left side -- image / icon */}
                <Box
                  className={
                    'aspect-square h-full items-center justify-center rounded-lg bg-gray-100'
                  }>
                  {rewardsListing.type === Listings.ListingType.ITEM ? (
                    <ShoppingBasketIcon className={'text-gray-500'} />
                  ) : (
                    <PercentIcon className={'text-gray-500'} />
                  )}
                </Box>

                {/* Right side -- Info section */}
                <HBox className={'h-full flex-1 justify-between'}>
                  <Box className={'flex-1 justify-between'}>
                    <Box>
                      <Text className={'line-clamp-1 font-semibold'}>
                        {`${rewardsListing.name} ${
                          rewardsListing.rewardsListingType ===
                            Rewards.ListingType.ITEM &&
                          rewardsListing.item.itemVariationName !== 'Default'
                            ? `(${rewardsListing.item.itemVariationName})`
                            : ''
                        }`}
                      </Text>
                      {!!rewardsListing.description && (
                        <Text variant={'muted'} className={'line-clamp-1'}>
                          {rewardsListing.description}
                        </Text>
                      )}
                    </Box>
                    <HBox className={'items-baseline gap-1'}>
                      <Text variant={'label'}>
                        {rewardsListing.pointsRequired}{' '}
                      </Text>
                      <Text variant={'label'}>
                        {rewardsProgramme?.pointName}
                      </Text>
                    </HBox>
                  </Box>
                  <Box>
                    <ArrowRightIcon className={'size-5'} />
                  </Box>
                </HBox>
              </Pressable>
            );
          })
        ) : (
          <Box className={'items-center justify-center'}>
            <Text>Stay tuned for exciting rewards!</Text>
          </Box>
        )}
      </Box>
    </>
  );
};

const RewardsPerksDialog = ({ onClose }: { onClose: () => void }) => {
  const { data: rewardsProgramme } = useRewardsProgrammeQuery();

  return (
    <Dialog open={true} onOpenChange={onClose}>
      <DialogContent>
        <DialogBody className={'gap-4'}>
          {rewardsProgramme.rewardsTiers.map((tier, index) => {
            return (
              <Box key={tier.id}>
                <Box>
                  <Text variant={'label'}>{tier.tierName}</Text>
                </Box>
                <Box>
                  <Text variant={'muted'}>
                    Earn {tier.pointMultiplier}x {rewardsProgramme?.pointName}
                  </Text>
                </Box>
              </Box>
            );
          })}
        </DialogBody>
      </DialogContent>
    </Dialog>
  );
};
