import { useSuspenseQuery } from '@tanstack/react-query';

import { Rewards } from '@waffle/common/src/models';

import buyersApiClient from '../utils/ApiService';
import { useSubdomainSellerQuery } from './sellersService';

export const useRewardsProgrammeQuery = () => {
  const { data: seller } = useSubdomainSellerQuery();

  return useSuspenseQuery<Rewards.RewardsProgramme>({
    queryKey: [`/rewards_programmes`],
    queryFn: async () => {
      const rewardsProgrammes: Rewards.RewardsProgramme[] = (
        await buyersApiClient.request({
          method: 'GET',
          url: '/rewards_programmes',
          params: {
            sellerId: seller.id,
          },
        })
      ).rewardsProgrammes;
      // Should only retrieve 1 RewardsProgramme since we are filtering by sellerId
      if (rewardsProgrammes.length !== 1) {
        throw new Error('Error fetching RewardsProgramme');
      }
      return rewardsProgrammes[0];
    },
  });
};

export const useAssertRewardsMembership = () => {
  const { data: seller } = useSubdomainSellerQuery();
  return useSuspenseQuery<Rewards.RewardsMembership>({
    queryKey: [`/rewards_memberships`, '/assert', { sellerId: seller?.id }],
    queryFn: async () => {
      return (
        await buyersApiClient.request({
          method: 'POST',
          url: `/rewards_memberships/assert`,
          params: {
            sellerId: seller?.id,
            expand: [
              'rewardsMembership.customer.customerSets',
              'rewardsMembership.rewardsTier.cardBackgroundImage',
            ],
          },
        })
      ).rewardsMembership;
    },
  });
};

export const useRewardsListingsQuery = () => {
  const { data: seller } = useSubdomainSellerQuery();
  return useSuspenseQuery<Rewards.RewardsListing[]>({
    queryKey: ['/rewards_listings', { sellerId: seller?.id }],
    queryFn: async () => {
      return (
        await buyersApiClient.request({
          method: 'GET',
          url: '/rewards_listings',
          params: {
            sellerId: seller.id,
            expand: ['rewardsListings.sellerLocations'],
          },
        })
      ).rewardsListings;
    },
  });
};

export const useIssuedRewardsQuery = () => {
  const { data: seller } = useSubdomainSellerQuery();

  return useSuspenseQuery<Rewards.IssuedReward[]>({
    queryKey: ['/issued_rewards', { sellerId: seller?.id }],
    queryFn: async () => {
      return (
        await buyersApiClient.request({
          method: 'GET',
          url: `/issued_rewards`,
          params: {
            sellerId: seller?.id,
            states: [Rewards.IssuedRewardState.ISSUED],
            expand: ['issuedRewards.listingSnapshot.sellerLocations'],
          },
        })
      ).issuedRewards;
    },
  });
};

export const useRewardsPointTransactionsQuery = () => {
  const { data: seller } = useSubdomainSellerQuery();
  return useSuspenseQuery<Rewards.PointTransaction[]>({
    queryKey: ['/rewards_point_transactions', { sellerId: seller?.id }],
    queryFn: async () => {
      const rewardsPointTransactions: Rewards.PointTransaction[] = (
        await buyersApiClient.request({
          method: 'GET',
          url: `/rewards_point_transactions`,
          params: { sellerId: seller?.id },
        })
      ).rewardsPointTransactions;

      return rewardsPointTransactions;
    },
  });
};
