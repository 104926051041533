import { useSuspenseQuery } from '@tanstack/react-query';

import { Buyers } from '@waffle/common/src/models';

import buyersApiClient from '../utils/ApiService';

/**
 * Hook to retrieve current buyer
 */
export const useBuyerUserQuery = () => {
  return useSuspenseQuery<Buyers.BuyerUser>({
    queryKey: ['/buyer_users', '/main'],
    queryFn: async () => {
      return (
        await buyersApiClient.request({
          method: 'GET',
          url: '/buyer_users/main',
        })
      ).buyerUser;
    },
  });
};

export const useIsBuyerUserProfileIncomplete = () => {
  const { data: buyerUser } = useBuyerUserQuery();
  const isProfileIncomplete: boolean =
    !buyerUser.mobileNumber ||
    !buyerUser.emailAddress ||
    !buyerUser.givenName ||
    !buyerUser.familyName ||
    !buyerUser.dateOfBirth;

  return isProfileIncomplete;
};
