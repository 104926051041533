/**
 * Button component from shadcn/ui
 *
 * Edits:
 * 1. Add an 'isLoading' state
 * 2. Rename 'disabled' to 'isDisabled'
 * 3. Add a 'pressable' variant and 'none' size
 * 4. Take in an onPress prop to be consistent with React Aria / GlueStack
 * 5. Implement <ButtonGroup> component for joining buttons together
 * 6. Add logic to prevent touch event from bleeding to parent
 */
import { Slot } from '@radix-ui/react-slot';
import { type VariantProps, cva } from 'class-variance-authority';
import { Loader2 } from 'lucide-react';
import * as React from 'react';

import { cn } from '../../lib/utils';
import { HBox } from './hbox';

const buttonVariants = cva(
  'ring-offset-background focus-visible:ring-ring inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        'destructive-outline':
          'border-destructive text-destructive hover:bg-destructive/10 border',
        outline:
          'border-input bg-background hover:bg-accent hover:text-accent-foreground disabled:bg-secondary border',
        'outline-destructive':
          'border-destructive bg-background hover:bg-destructive-foreground text-destructive border',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        unstyled: '', // Don't apply any styles
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
        none: 'p-0', // Will be the size of its contents
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  onPress?: () => void;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      isLoading = false,
      isDisabled = false,
      onPress,
      children,
      ...props
    },
    ref,
  ) => {
    /**
     * Don't allow touch event to bleed to parent
     */
    const handleClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      onPress?.();
    };

    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size }), className)}
        ref={ref}
        disabled={isLoading || isDisabled}
        onClick={handleClick}
        {...props}>
        {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
        {children}
      </Comp>
    );
  },
);
Button.displayName = 'Button';

interface ButtonGroupProps {
  children: React.ReactNode;
  [key: string]: ButtonProps; // Allow any additional props from Button
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({
  children,
  className,
  ...props
}) => {
  const childrenArray = React.Children.toArray(children);

  return (
    <HBox>
      {React.Children.map(childrenArray, (child, index) => {
        if (!React.isValidElement(child)) return child;

        let buttonGroupClassNames = 'rounded-none border-l-0';
        if (index === 0) {
          buttonGroupClassNames = 'rounded-r-none';
        } else if (index === childrenArray.length - 1) {
          buttonGroupClassNames = 'rounded-l-none border-l-0';
        }

        return React.cloneElement(child as React.ReactElement, {
          ...props,
          className: cn(
            buttonGroupClassNames,
            className,
            child.props.className,
          ),
        });
      })}
    </HBox>
  );
};

export { Button, buttonVariants, ButtonGroup };
