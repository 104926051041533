import _ from 'lodash';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';

import { Rewards } from '@waffle/common/src/models';
import {
  Badge,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  HBox,
  Text,
  VirtualList,
} from '@waffle/ui-web';

import {
  useRewardsPointTransactionsQuery,
  useRewardsProgrammeQuery,
} from '../../../services/rewardsService';

type RowData =
  | {
      type: 'header';
      header: string;
    }
  | {
      type: 'data';
      data: Rewards.PointTransaction;
    };

export const RewardsActivityPage = () => {
  const { data: rewardsProgramme } = useRewardsProgrammeQuery();
  const { data: rewardsPointTransactions } = useRewardsPointTransactionsQuery();

  // Transform data into flat list with headers
  const items = useMemo(() => {
    // Group transactions by date
    const rewardsPointsTransactionsGroupedByMonth = _.groupBy(
      rewardsPointTransactions,
      (rpt) => DateTime.fromISO(rpt.createdAt).toFormat('yyyy-MM'),
    );

    // Convert to flat array with headers
    return Object.entries(rewardsPointsTransactionsGroupedByMonth).flatMap(
      ([date, rewardsPointTransaction]) => {
        const items: RowData[] = [
          {
            type: 'header',
            header: date,
          },
          ...rewardsPointTransaction.map(
            (x): RowData => ({
              type: 'data',
              data: x,
            }),
          ),
        ];
        return items;
      },
    );
  }, [rewardsPointTransactions]);

  const renderItem = ({ item }: { item: RowData; index: number }) => {
    if (item.type === 'header') {
      return (
        <Box className={'h-[48px] justify-end p-2'}>
          <Text variant={'label-muted'}>
            {DateTime.fromISO(item.header).toFormat('MMMM yyyy')}
          </Text>
        </Box>
      );
    }

    return (
      <HBox className={'grid h-[64px] grid-cols-3 p-2'}>
        <Box className={'items-start'}>
          <Badge>
            {item.data.type === Rewards.PointTransactionType.REWARD_REDEMPTION
              ? 'Redemption'
              : item.data.type === Rewards.PointTransactionType.CAMPAIGN_ISSUED
                ? 'Earned'
                : item.data.type === Rewards.PointTransactionType.EXPIRY
                  ? 'Expired'
                  : item.data.type ===
                      Rewards.PointTransactionType.MANUAL_ISSUED
                    ? 'Manual Issued'
                    : item.data.type === Rewards.PointTransactionType.VOIDED
                      ? 'Voided'
                      : 'Unknown'}
          </Badge>
        </Box>

        <Box>
          <Text variant={'label'}>
            {DateTime.fromISO(item.data.createdAt).toLocaleString(
              DateTime.DATE_MED,
            )}
          </Text>
          <Text variant={'muted'}>
            {DateTime.fromISO(item.data.createdAt).toLocaleString(
              DateTime.TIME_SIMPLE,
            )}
          </Text>
        </Box>

        <Box className={'items-end'}>
          <Text variant={'label'}>
            {item.data.pointsAwarded > 0 ? '+' : '-'}
            {Math.abs(item.data.pointsAwarded)}
          </Text>
        </Box>
      </HBox>
    );
  };

  return (
    <HBox className="justify-center py-8">
      <Box className="w-full max-w-[1024px]">
        <Box className={'mb-4'}>
          <Text variant="h1">Rewards Activity</Text>
        </Box>

        <Card className={'border-none'}>
          <CardHeader className={'bg-background sticky top-0 z-10 shadow-sm'}>
            <Grid className={'grid-cols-3'}>
              <Box>
                <Text variant={'label'}>Type</Text>
              </Box>
              <Box>
                <Text variant={'label'}>Date</Text>
              </Box>
              <Box className={'items-end'}>
                <Text variant={'label'}>
                  {rewardsProgramme.pointName ?? 'Points'}
                </Text>
              </Box>
            </Grid>
          </CardHeader>

          <CardContent>
            <VirtualList
              data={items}
              renderItem={renderItem}
              // isLoadingMore={isLoadingMore}
              estimateSize={(index) => {
                if (items[index].type === 'header') {
                  return 48;
                }
                return 64;
              }}
              keyExtractor={(item, index) =>
                item.type === 'header' ? `header-${item.header}` : item.data.id
              }
              ListEmptyComponent={
                <Box className={'h-[64px] items-center justify-center p-2'}>
                  <Text variant={'muted'}>No data to display</Text>
                </Box>
              }
            />
          </CardContent>
        </Card>
      </Box>
    </HBox>
  );
};
