import { useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  HBox,
  Label,
  Separator,
  Text,
  TextInput,
  useToast,
} from '@waffle/ui-web';
import { CalendarIcon, MailIcon, PhoneIcon } from '@waffle/ui-web/icons';

import { useBuyerUserQuery } from '../../../services/buyersService';
import buyersApiClient from '../../../utils/ApiService';

const exclamationColor = '#f9c044';

export const ProfilePage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const toast = useToast();

  const { data: buyerUser } = useBuyerUserQuery();

  const [givenName, setGivenName] = useState<string | undefined>(undefined);
  const [familyName, setFamilyName] = useState<string | undefined>(undefined);
  const [dateOfBirth, setDateOfBirth] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);

  useEffect(() => {
    setGivenName(buyerUser?.givenName ?? undefined);
    setFamilyName(buyerUser?.familyName ?? undefined);
    setDateOfBirth(buyerUser?.dateOfBirth ?? undefined);
    setEmail(buyerUser?.emailAddress ?? undefined);
  }, [buyerUser]);

  const [isSaveButtonActive, setIsSaveButtonActive] = useState<
    boolean | undefined
  >(false);

  const handleSubmitBuyerUser = async () => {
    try {
      // Only given name is compulsory
      if (!givenName) {
        toast.show({
          title: 'Failed to save profile',
          description: 'Please key in your name!',
          status: 'error',
        });
        return;
      }

      // If email address was supplied but in an invalid format
      const emailRegexp: RegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if (email && !emailRegexp.test(email)) {
        toast.show({
          title: 'Failed to save profile',
          description: 'Email Address is in an invalid format!',
          status: 'error',
        });
        return;
      }

      let formattedDateObject;
      if (!buyerUser.dateOfBirth && dateOfBirth) {
        if (dateOfBirth.length === 8) {
          const day: number = parseInt(dateOfBirth.slice(0, 2));
          const month: number = parseInt(dateOfBirth.slice(2, 4));
          const year: number = parseInt(dateOfBirth.slice(-4));
          formattedDateObject = DateTime.local(year, month, day);
          if (
            !formattedDateObject.isValid ||
            formattedDateObject > DateTime.now()
          ) {
            // DOB not valid
            toast.show({
              title: 'Failed to save profile',
              description: 'Date of Birth is not valid.',
              status: 'error',
            });
            return;
          }
          // Only reaches here if DOB is 8 digit and has valid input
        } else {
          // DOB not 8 digits
          toast.show({
            title: 'Failed to save profile',
            description:
              'Date of Birth is in an invalid format. Should be DDMMYYYY.',
            status: 'error',
          });
          return;
        }
      }

      await buyersApiClient.request({
        method: 'PUT',
        url: `/buyer_users/${buyerUser.id}`,
        data: {
          buyerUser: {
            id: buyerUser.id,
            emailAddress: email || null, // don't allow them to submit empty string if they backspace
            mobileNumber: buyerUser.mobileNumber,
            givenName: givenName,
            familyName: familyName,
            dateOfBirth: buyerUser.dateOfBirth
              ? DateTime.fromISO(buyerUser.dateOfBirth).toISODate() // prevents change in timezone when re-upserting to the database
              : formattedDateObject?.isValid // check that it is not an Invalid Date
                ? formattedDateObject.toISODate()
                : undefined,
          },
        },
      });

      await queryClient.invalidateQueries({ queryKey: ['/buyer_users'] });

      toast.show({
        title: 'Profile has been saved',
        status: 'success',
      });
      setIsSaveButtonActive(false);
      navigate('/rewards');
    } catch (error) {
      toast.show({
        title: 'Failed to save profile',
        description: 'There was an error, please check the inputs again.',
        status: 'error',
      });
    }
  };

  return (
    <Box>
      <Box className={'mb-2'}>
        <Text variant={'h2'}>Profile settings</Text>
      </Box>

      <Card>
        <CardContent>
          <Grid className={'grid-cols-2 gap-8'}>
            {/* Given name */}
            <Box>
              <Label>Given name</Label>
              <TextInput
                autoCapitalize={'words'}
                autoCompleteType={'name'}
                autoCorrect={false}
                placeholder={'Jane'}
                defaultValue={givenName}
                onChangeText={(newGivenName) => {
                  setGivenName(newGivenName);
                  setIsSaveButtonActive(true);
                }}
              />
            </Box>

            {/*/!* Family Name *!/*/}
            <Box>
              <Label>Family name</Label>
              <TextInput
                autoCapitalize={'words'}
                autoCompleteType={'name'}
                autoCorrect={false}
                placeholder={'Doe'}
                defaultValue={familyName}
                onChangeText={(newFamilyName) => {
                  setFamilyName(newFamilyName);
                  setIsSaveButtonActive(true);
                }}
              />
            </Box>
          </Grid>
        </CardContent>
        <Separator />
        <CardContent>
          {/* Date of birth */}
          <Box>
            <HBox>
              <Label>Date of birth</Label>
              {!!dateOfBirth ? null : (
                <FaExclamationCircle color={exclamationColor} />
              )}
            </HBox>
            <TextInput
              keyboardType={'number-pad'}
              placeholder={'DDMMYYYY'}
              isDisabled={!!buyerUser.dateOfBirth}
              value={
                buyerUser.dateOfBirth
                  ? new Date(buyerUser.dateOfBirth).toLocaleDateString()
                  : dateOfBirth
              }
              onChangeText={(newDateOfBirth) => {
                setDateOfBirth(newDateOfBirth);
                setIsSaveButtonActive(true);
              }}
              rightElement={<CalendarIcon className={'size-4'} />}
            />
          </Box>
        </CardContent>
        <Separator />
        <CardContent>
          {/* Mobile Number */}
          <Label>Mobile number</Label>
          <TextInput
            isDisabled
            value={buyerUser?.mobileNumber ?? undefined}
            rightElement={<PhoneIcon className={'size-4'} />}
          />
        </CardContent>
        <Separator />
        <CardContent>
          {/* Email Address */}
          <Box>
            <HBox>
              <Label>Email address</Label>
            </HBox>

            <TextInput
              placeholder={'jane.doe@example.com'}
              autoCompleteType={'email'}
              autoCorrect={false}
              defaultValue={email}
              onChangeText={(newEmail) => {
                setEmail(newEmail);
                setIsSaveButtonActive(true);
              }}
              rightElement={<MailIcon className={'size-4'} />}
            />
          </Box>
        </CardContent>

        <Separator />

        <CardContent>
          <Button
            isDisabled={!isSaveButtonActive}
            onPress={handleSubmitBuyerUser}>
            Save
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};
