/**
 * Custom component built by @zames
 * This behaves like a <Box> but a bit like a <Button> as well, taking in onPress, isDisabled, etc.
 */
import * as React from 'react';

import { cn } from '../../lib/utils';

export interface PressableProps {
  onPress?: () => void;
  isDisabled?: boolean;
  children?: React.ReactNode;
}

export const Pressable = React.forwardRef<HTMLButtonElement, PressableProps>(
  ({ onPress, isDisabled = false, className, children, ...props }, ref) => {
    /**
     * Don't allow touch event to bleed to parent
     */
    const handleClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      onPress?.();
    };

    return (
      <button
        ref={ref}
        role="button"
        tabIndex={isDisabled ? -1 : 0}
        className={cn(
          'flex cursor-pointer flex-col items-stretch justify-start text-left disabled:cursor-not-allowed disabled:opacity-50',
          className,
        )}
        onClick={handleClick}
        disabled={isDisabled}
        aria-disabled={isDisabled}
        data-disabled={isDisabled}
        {...props}>
        {children}
      </button>
    );
  },
);

Pressable.displayName = 'Pressable';
