import { useSuspenseQuery } from '@tanstack/react-query';

import { Customers } from '@waffle/common/src/models';

import buyersApiClient from '../utils/ApiService';
import { useSubdomainSellerQuery } from './sellersService';

export const useCustomerQuery = () => {
  const { data: seller } = useSubdomainSellerQuery();
  return useSuspenseQuery({
    queryKey: ['/customers', { sellerId: seller?.id }],
    queryFn: async () => {
      const customers: Customers.Customer[] = (
        await buyersApiClient.request({
          method: 'GET',
          url: '/customers',
          params: { sellerId: seller?.id },
          validateStatus: (status) => {
            if (status >= 400 && status !== 404) {
              return false;
            }
            return true;
          },
        })
      ).customers;
      return customers;
    },
    select: (customers: Customers.Customer[]) => {
      if (customers?.length !== 1) {
        return null;
      }
      return customers[0];
    },
  });
};
