import _ from 'lodash';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';

import { Orders } from '@waffle/common/src/models';
import { MoneyUtil } from '@waffle/common/src/util/money/MoneyUtil';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Box,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Grid,
  HBox,
  Pressable,
  Text,
  VirtualList,
  cn,
} from '@waffle/ui-web';
import { StoreIcon } from '@waffle/ui-web/icons';

import { useInfiniteGetOrdersQuery } from '../../../services/ordersService';
import { TransactionDialogContent } from './TransactionDialog';

type RowData =
  | {
      type: 'header';
      header: string;
    }
  | {
      type: 'data';
      data: Orders.SaleOrder;
    };

const LIMIT = 20;
export const TransactionsPage = () => {
  //   const [createdAtAfter, setCreatedAtAfter] = useState<string | undefined>(
  //     undefined,
  //   );
  //   const [createdAtBefore, setCreatedAtBefore] = useState<string | undefined>(
  //     undefined,
  //   );

  const {
    data: orderPages,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteGetOrdersQuery({
    //   createdAtAfter: createdAtAfter,
    //   createdAtBefore: createdAtBefore,
    limit: LIMIT,
    expand: ['orders.location', 'orders.seller.logoImage'],
  });

  const orders = useMemo(
    () => orderPages?.pages.flatMap((page) => page) ?? [],
    [orderPages],
  );

  const [selectedOrderId, setSelectedOrderId] = useState<string | undefined>(
    undefined,
  );

  // Transform data into flat list with headers
  const items: RowData[] = useMemo(() => {
    // Group Orders by month
    const groupedOrders = _.groupBy(orders, (order) =>
      DateTime.fromISO(order.createdAt).toFormat('yyyy-MM'),
    );

    // Convert to flat array with headers
    return Object.entries(groupedOrders).flatMap(([date, transactions]) => {
      const items: RowData[] = [
        {
          type: 'header',
          header: date,
        },
        ...transactions.map(
          (data): RowData => ({
            type: 'data',
            data: data,
          }),
        ),
      ];
      return items;
    });
  }, [orders]);

  const renderItem = ({ item }: { item: RowData; index: number }) => {
    if (item.type === 'header') {
      return (
        <Box className="h-[48px] justify-end p-2">
          <Text variant={'label-muted'}>
            {DateTime.fromISO(item.header).toFormat('MMMM yyyy')}
          </Text>
        </Box>
      );
    }

    return (
      <Pressable
        onPress={() => {
          setSelectedOrderId(item.data.id);
        }}
        className="grid h-[64px] w-full grid-cols-[4fr_1fr] gap-2 rounded-lg p-2 hover:bg-gray-100 md:grid-cols-[4fr_1fr_1fr]">
        <HBox className={'items-center gap-2 overflow-hidden'}>
          <Avatar className={'h-[40px] w-[40px]'}>
            <AvatarImage src={item.data.seller?.logoImage?.thumbnailUrl} />
            <AvatarFallback>
              <StoreIcon className={'text-gray-500'} />
            </AvatarFallback>
          </Avatar>

          <Box className={'flex-1 truncate'}>
            <Text variant={'label'} className={'line-clamp-1'}>
              {item.data.seller?.name}
            </Text>
            <Text variant={'muted'} className={'line-clamp-1'}>
              {item.data.location?.name}
            </Text>
          </Box>
        </HBox>
        {/* Hidden on smaller devices */}
        <Box className={'hidden md:block'}>
          <Text variant={'label'}>
            {DateTime.fromISO(item.data.createdAt).toLocaleString(
              DateTime.DATE_MED,
            )}
          </Text>
          <Text variant={'muted'}>
            {DateTime.fromISO(item.data.createdAt).toLocaleString(
              DateTime.TIME_SIMPLE,
            )}
          </Text>
        </Box>
        <Box className={'truncate text-right'}>
          <Text
            variant={'label'}
            className={cn(
              item.data.returns.length > 0
                ? 'text-muted-foreground line-through'
                : '',
            )}>
            {MoneyUtil.formatCurrency({
              amount: item.data.totalAmount,
              currencyCode: item.data.seller?.defaultCurrencyCode,
            })}
          </Text>
        </Box>
      </Pressable>
    );
  };

  return (
    <>
      <HBox className="justify-center py-8">
        <Box className="w-full max-w-[1024px]">
          <Box className={'mb-4'}>
            <Text variant="h1">Transactions</Text>
          </Box>

          <Card className={'border-none'}>
            <CardHeader className={'bg-background sticky top-0 z-10 shadow-sm'}>
              <Grid
                className={'grid-cols-[4fr_1fr] md:grid-cols-[4fr_1fr_1fr]'}>
                <Box>
                  <Text variant={'label'}>Location</Text>
                </Box>

                <Box className={'hidden md:block'}>
                  <Text variant={'label'}>Date</Text>
                </Box>

                <Box className={'items-end'}>
                  <Text variant={'label'}>Amount</Text>
                </Box>
              </Grid>
            </CardHeader>

            <CardContent>
              <VirtualList
                data={items}
                renderItem={renderItem}
                isLoadingMore={isFetchingNextPage}
                onEndReached={() => {
                  fetchNextPage();
                }}
                estimateSize={(index) => {
                  if (items[index].type === 'header') {
                    return 48;
                  }
                  return 64;
                }}
                keyExtractor={(item, index) =>
                  item.type === 'header'
                    ? `header-${item.header}`
                    : item.data.id
                }
                ListEmptyComponent={
                  <Box className={'h-[64px] items-center justify-center p-2'}>
                    <Text variant={'muted'}>No data to display</Text>
                  </Box>
                }
              />
            </CardContent>
          </Card>
        </Box>
      </HBox>

      {!!selectedOrderId && (
        <Dialog
          open={true}
          onOpenChange={() => {
            setSelectedOrderId(undefined);
          }}>
          <TransactionDialogContent orderId={selectedOrderId} />
        </Dialog>
      )}
    </>
  );
};
