import {
  useSuspenseInfiniteQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { DateTime } from 'luxon';

import { Orders } from '@waffle/common/src/models';

import buyersApiClient from '../utils/ApiService';
import { useSubdomainSellerQuery } from './sellersService';

export const useGetOrderQuery = ({
  orderId,
  expand,
}: {
  orderId: string;
  expand?: string[];
}) => {
  const { data: seller } = useSubdomainSellerQuery();
  return useSuspenseQuery({
    queryKey: [
      Orders.Order._type,
      { sellerId: seller.id, orderId: orderId, expand: expand },
    ],
    queryFn: async (): Promise<Orders.SaleOrder> => {
      return (
        await buyersApiClient.request({
          method: 'GET',
          url: `/orders/${orderId}`,
          params: {
            sellerId: seller.id,
            expand: expand,
          },
        })
      ).order;
    },
  });
};

export const useInfiniteGetOrdersQuery = ({
  createdAtAfter,
  createdAtBefore,
  limit,
  expand,
}: {
  createdAtAfter?: string;
  createdAtBefore?: string;
  limit?: number;
  expand?: string[];
}) => {
  const { data: seller } = useSubdomainSellerQuery();
  return useSuspenseInfiniteQuery({
    queryKey: [
      Orders.Order._type,
      {
        sellerId: seller.id,
        createdAtAfter: createdAtAfter,
        createdAtBefore: createdAtBefore,
        limit: limit,
        expand: expand,
      },
    ],
    queryFn: async ({ pageParam: lastOrderCreatedAt }) => {
      return (
        await buyersApiClient.request({
          method: 'GET',
          url: `/orders`,
          params: {
            sellerId: seller.id,
            createdAtAfter: createdAtAfter,
            // Pick the earlier date
            createdAtBefore:
              createdAtBefore < lastOrderCreatedAt
                ? createdAtBefore
                : lastOrderCreatedAt,
            limit: limit,
            expand: expand,
          },
        })
      ).orders;
    },
    initialPageParam: DateTime.now().toISO(),
    getNextPageParam: (lastPage) => {
      // Determine the next page's cursor using the 'createdAt' of the last order
      const lastOrder = lastPage[lastPage.length - 1];
      return lastOrder ? lastOrder.createdAt : undefined;
    },
  });
};
