import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

import * as Common from '../common';
import * as CustomerSets from '../customerSets';
import * as Customers from '../customers';
import * as Listings from '../listings';
import * as Orders from '../orders';
import * as Sales from '../sales';
import * as Sellers from '../sellers';

/**
 * Rewards.IssuedReward
 */
export enum IssuedRewardState {
  ISSUED = 'ISSUED',
  REDEEMED = 'REDEEMED',
  EXPIRED = 'EXPIRED',
  VOIDED = 'VOIDED',
}

/**
 * Rewards.RewardsListing
 */
export enum ListingType {
  ITEM = 'ITEM',
  DISCOUNT = 'DISCOUNT',
  // VOUCHER = 'VOUCHER',
}

export enum IssuanceType {
  CAMPAIGN_ISSUED = 'CAMPAIGN_ISSUED',
  POINTS_REDEMPTION = 'POINTS_REDEMPTION',
}

export enum PointsRoundingMode {
  UP = 'UP',
  DOWN = 'DOWN',
  NEAREST = 'NEAREST',
}

/**
 * Rewards.Trigger
 */
// TODO: Move triggers into another file, but find an elegant way to allow import together with the rest of the modules
// Currently if separate each trigger into their own file quite messy, but if put all into 1 file then need to import on different line
export enum TriggerType {
  /** When buyer signs up for a rewards programme for the first time **/
  FIRST_SIGN_UP = 'FIRST_SIGN_UP',

  /** Buyer's birthday **/
  BIRTHDAY = 'BIRTHDAY',

  /** Award points depending on how much was spent on the sale **/
  SALE_AMOUNT_SPENT = 'SALE_AMOUNT_SPENT',

  /** When buyer purchases a certain listing in their sale, triggered once per quantity **/
  SALE_LISTING_QUANTITY = 'SALE_LISTING_QUANTITY',
}

/**
 * Rewards.CampaignTargetType
 */
export enum CampaignTargetType {
  INCLUDE = 'INCLUDE',
  EXCLUDE = 'EXCLUDE',
}

/**
 * Rewards.TriggerWindowType
 */
export enum TriggerWindowType {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export type RewardsTier = z.infer<typeof RewardsTier.schema> & {
  cardBackgroundImage?: Common.Image;
};
export namespace RewardsTier {
  export const _type = 'rewards.rewards_tier' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    rewardsProgrammeId: z.string().uuid(),
    tierName: z.string(),
    pointsRequired: z.number(),
    pointMultiplier: z.number().min(0).max(100),
    cardFontColor: z.string().nullable().default(null),
    cardBackgroundColor: z.string().nullable().default(null),
    cardBackgroundImageUrl: z.string().nullable().default(null), // TODO: Deprecate, use cardBackgroundImage instead
    cardBackgroundImageId: z.string().uuid().nullable().default(null),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (args: Partial<RewardsTier> | any): RewardsTier => {
    return schema.parse({
      ...args,
      _type: _type,
      id: args.id ?? args.rewardsTierId,
    });
  };
}

export type RewardsTierCreateParams = z.infer<
  typeof RewardsTierCreateParams.schema
>;
export namespace RewardsTierCreateParams {
  export const schema = z.object({
    tierName: z.string(),
    pointsRequired: z.number(),
    pointMultiplier: z.number().min(0).max(100),
    cardFontColor: z.string().nullable().default(null),
    cardBackgroundColor: z.string().nullable().default(null),
    cardBackgroundImageUrl: z.string().nullable().default(null),
    cardBackgroundImageId: z.string().uuid().nullable().default(null),
  });
}

export type RewardsTierUpdateParams = z.infer<
  typeof RewardsTierUpdateParams.schema
>;
export namespace RewardsTierUpdateParams {
  export const schema = z.object({
    id: z.string().uuid().optional(),
    tierName: z.string().nullish(),
    pointsRequired: z.number().nullish(),
    pointMultiplier: z.number().min(0).max(100).nullish(),
    cardFontColor: z.string().nullish(),
    cardBackgroundColor: z.string().nullish(),
    cardBackgroundImageUrl: z.string().nullish(),
    cardBackgroundImageId: z.string().uuid().nullish(),
  });
}

export type RewardsProgramme = z.infer<typeof RewardsProgramme.schema>;
export namespace RewardsProgramme {
  export const _type = 'rewards.rewards_programme' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    rewardsProgrammeName: z.string(),
    pointName: z.string(),
    pointsRoundingMode: z.nativeEnum(PointsRoundingMode),
    pointsExpiryWindowMonths: z.number().nullable(),
    isPointsExpiryEnabled: z.boolean(),
    sellerId: z.string().uuid(),
    rewardsTiers: z.array(RewardsTier.schema).default([]),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (
    args:
      | (Partial<RewardsProgramme> & Pick<RewardsProgramme, 'rewardsTiers'>)
      | any,
  ): RewardsProgramme => {
    return schema.parse({
      ...args,
      _type: _type,
      id: args.id ?? args.rewardsProgrammeId,
      rewardsTiers: args.rewardsTiers.map(RewardsTier.create),
    });
  };
}

export type RewardsProgrammeCreateParams = z.infer<
  typeof RewardsProgrammeCreateParams.schema
>;
export namespace RewardsProgrammeCreateParams {
  export const schema = z.object({
    rewardsProgrammeName: z.string(),
    pointName: z.string(),
    pointsRoundingMode: z.nativeEnum(PointsRoundingMode),
    pointsExpiryWindowMonths: z.number().nullable(),
    isPointsExpiryEnabled: z.boolean().default(false),
    rewardsTiers: z.array(RewardsTierCreateParams.schema).default([]),
  });
}

export type RewardsProgrammeUpdateParams = z.infer<
  typeof RewardsProgrammeUpdateParams.schema
>;
export namespace RewardsProgrammeUpdateParams {
  export const schema = z.object({
    rewardsProgrammeName: z.string().nullish(),
    pointName: z.string().nullish(),
    pointsRoundingMode: z.nativeEnum(PointsRoundingMode).nullish(),
    pointsExpiryWindowMonths: z.number().nullish(),
    isPointsExpiryEnabled: z.boolean().nullish(),
    rewardsTiers: z
      .array(RewardsTierUpdateParams.schema, RewardsTierCreateParams.schema)
      .nullish(),
  });
}

export const RewardsListingBase = z.object({
  _type: z.literal('rewards.rewards_listing'),
  id: z.string().uuid(),
  rewardsProgrammeId: z.string().uuid(),

  issuanceType: z.nativeEnum(IssuanceType),
  rewardsListingType: z.nativeEnum(ListingType), // TODO: deprecate, use type
  type: z.nativeEnum(ListingType),

  name: z.string(), // TODO: Add min length
  pointsRequired: z.number().min(0).default(0),
  description: z.string().nullable().default(null), // TODO: Add min length

  // Location filters affects both issuance and redemption
  presentAtAllLocations: z.boolean().default(true),
  sellerLocationIds: z.array(z.string()).default([]),
  // CustomerSet filters affects only issuance. For campaign-issued rewards, isIssuableToAllCustomers is always true since the campaign handles issuance
  isIssuableToAllCustomers: z.boolean().default(true),
  customerSetIds: z.array(z.string()).default([]),

  createdAt: z
    .string()
    .datetime({ offset: true })
    .default(() => new Date().toISOString()),
  updatedAt: z
    .string()
    .datetime({ offset: true })
    .default(() => new Date().toISOString()),
  isDeleted: z.boolean().default(false),
});

export type RewardsListingItem = z.infer<typeof RewardsListingItem.schema>;
export namespace RewardsListingItem {
  export const _type = 'rewards.rewards_listing' as const;
  export const schema = RewardsListingBase.extend({
    rewardsListingType: z.literal(ListingType.ITEM).default(ListingType.ITEM), // TODO: deprecate, use `type` field
    type: z.literal(ListingType.ITEM).default(ListingType.ITEM),

    item: z.object({
      itemId: z.string().uuid(),
      itemVariationId: z.string().uuid(),
      itemVariationName: z.string(),
      categoryId: z.string().uuid().nullable(),
      categoryName: z.string().nullable(),
      price: z.number(),
    }),
  });
  export const create = (
    args: Partial<RewardsListingItem> | any,
  ): RewardsListingItem => {
    return schema.parse({
      ...args,
      _type: _type,
      id: args.id ?? args.rewardsItemId,
      type: ListingType.ITEM,
    });
  };
}

export type RewardsListingItemCreateParams = z.infer<
  typeof RewardsListingItemCreateParams.schema
>;
export namespace RewardsListingItemCreateParams {
  export const schema = z.object({
    issuanceType: z.nativeEnum(IssuanceType),
    rewardsListingType: z.literal(ListingType.ITEM).default(ListingType.ITEM), // TODO: deprecate, use `type` field
    type: z.literal(ListingType.ITEM).default(ListingType.ITEM),

    name: z.string(), // TODO: Add min length
    pointsRequired: z.number().min(0).default(0),
    description: z.string().nullable().default(null), // TODO: Add min length

    presentAtAllLocations: z.boolean().default(true),
    sellerLocationIds: z.array(z.string()).default([]),
    isIssuableToAllCustomers: z.boolean().default(true),
    customerSetIds: z.array(z.string()).default([]),

    item: z.object({
      itemId: z.string().uuid(),
      itemVariationId: z.string().uuid(),
      itemVariationName: z.string(),
      categoryId: z.string().uuid().nullable(),
      categoryName: z.string().nullable(),
      price: z.number(),
    }),
  });
}

export type RewardsListingItemUpdateParams = z.infer<
  typeof RewardsListingItemUpdateParams.schema
>;
export namespace RewardsListingItemUpdateParams {
  export const schema = z.object({
    rewardsListingType: z.literal(ListingType.ITEM).default(ListingType.ITEM), // TODO: deprecate, use `type` field
    type: z.literal(ListingType.ITEM).default(ListingType.ITEM),

    name: z.string(), // TODO: Add min length
    pointsRequired: z.number().min(0).nullish(),
    description: z.string().nullish(), // TODO: Add min length

    presentAtAllLocations: z.boolean().optional(),
    sellerLocationIds: z.array(z.string()).optional(),
    isIssuableToAllCustomers: z.boolean().optional(),
    customerSetIds: z.array(z.string()).optional(),

    item: z.object({
      itemId: z.string().uuid(),
      itemVariationId: z.string().uuid().nullish(),
      itemVariationName: z.string().nullish(),
      categoryId: z.string().uuid().nullish(),
      categoryName: z.string().nullish(),
      price: z.number().nullish(),
    }),
  });
}

export type RewardsListingDiscount = z.infer<
  typeof RewardsListingDiscount.schema
>;
export namespace RewardsListingDiscount {
  export const _type = 'rewards.rewards_listing' as const;
  export const schema = RewardsListingBase.extend({
    rewardsListingType: z
      .literal(ListingType.DISCOUNT)
      .default(ListingType.DISCOUNT), // to deprecate, use type
    type: z.literal(ListingType.DISCOUNT).default(ListingType.DISCOUNT),

    /** Discount Specific Stuff **/
    discount: z.object({
      shouldApplyToAllAddOns: z.boolean(),
      unit: z.nativeEnum(Common.ModifierUnit),
      discountType: z.nativeEnum(Listings.DiscountType),
      dollarValue: z.number(),
      percentValue: z.number(),
      isVariable: z.boolean(),
    }),
  });
  export const create = (
    args: Partial<RewardsListingDiscount> | any,
  ): RewardsListingDiscount => {
    return schema.parse({
      ...args,
      _type: _type,
      id: args.id ?? args.rewardsDiscountId,
      type: ListingType.DISCOUNT,
    });
  };
}

export type RewardsListingDiscountCreateParams = z.infer<
  typeof RewardsListingDiscountCreateParams.schema
>;
export namespace RewardsListingDiscountCreateParams {
  export const schema = RewardsListingBase.extend({
    issuanceType: z.nativeEnum(IssuanceType),
    rewardsListingType: z
      .literal(ListingType.DISCOUNT)
      .default(ListingType.DISCOUNT), // to deprecate, use type
    type: z.literal(ListingType.DISCOUNT).default(ListingType.DISCOUNT),

    name: z.string(), // TODO: Add min length
    pointsRequired: z.number().min(0).default(0),
    description: z.string().nullable().default(null), // TODO: Add min length

    presentAtAllLocations: z.boolean().default(true),
    sellerLocationIds: z.array(z.string()).default([]),
    isIssuableToAllCustomers: z.boolean().default(true),
    customerSetIds: z.array(z.string()).default([]),

    /** Discount Specific Stuff **/
    discount: z.object({
      shouldApplyToAllAddOns: z.boolean(),
      unit: z.nativeEnum(Common.ModifierUnit),
      discountType: z.nativeEnum(Listings.DiscountType),
      dollarValue: z.number(),
      percentValue: z.number(),
      isVariable: z.boolean(),
    }),
  });
}

export type RewardsListingDiscountUpdateParams = z.infer<
  typeof RewardsListingDiscountUpdateParams.schema
>;
export namespace RewardsListingDiscountUpdateParams {
  export const schema = RewardsListingBase.extend({
    id: z.string().uuid().optional(),
    type: z.literal(ListingType.DISCOUNT).default(ListingType.DISCOUNT),
    rewardsListingType: z
      .literal(ListingType.DISCOUNT)
      .default(ListingType.DISCOUNT), // TODO: deprecate, use `type` field

    name: z.string(), // TODO: Add min length
    pointsRequired: z.number().min(0).nullish(),
    description: z.string().nullish(), // TODO: Add min length

    presentAtAllLocations: z.boolean().optional(),
    sellerLocationIds: z.array(z.string()).optional(),
    isIssuableToAllCustomers: z.boolean().optional(),
    customerSetIds: z.array(z.string()).optional(),

    /** Discount Specific Stuff **/
    discount: z.object({
      shouldApplyToAllAddOns: z.boolean().nullish(),
      unit: z.nativeEnum(Common.ModifierUnit).nullish(),
      dollarValue: z.number().nullish(),
      percentValue: z.number().nullish(),
      isVariable: z.boolean().nullish(),
    }),
  });
}

export type RewardsListing = z.infer<typeof RewardsListing.schema> & {
  sellerLocations: Sellers.Location[];
  issuableToCustomerSets: CustomerSets.CustomerSet[];
};
export namespace RewardsListing {
  export const _type = 'rewards.rewards_listing' as const;
  export const schema = z.discriminatedUnion('type', [
    RewardsListingDiscount.schema,
    RewardsListingItem.schema,
  ]);
  export const create = (
    args: any | Partial<RewardsListing>,
  ): RewardsListing => {
    const type = args.type ?? args.rewardsListingType;
    switch (type) {
      case ListingType.ITEM: {
        return RewardsListingItem.create(args);
      }
      case ListingType.DISCOUNT: {
        return RewardsListingDiscount.create(args);
      }
      default: {
        throw new Error('Unrecognized Listing type');
      }
    }
  };
}

export type RewardsListingCreateParams = z.infer<
  typeof RewardsListingCreateParams.schema
>;
export namespace RewardsListingCreateParams {
  export const schema = z.discriminatedUnion('type', [
    RewardsListingDiscountCreateParams.schema,
    RewardsListingItemCreateParams.schema,
  ]);
}

export type RewardsListingUpdateParams = z.infer<
  typeof RewardsListingUpdateParams.schema
>;
export namespace RewardsListingUpdateParams {
  export const schema = z.discriminatedUnion('type', [
    RewardsListingDiscountUpdateParams.schema,
    RewardsListingItemUpdateParams.schema,
  ]);
}

export enum IssuedRewardSourceType {
  CAMPAIGN_ISSUED = 'CAMPAIGN_ISSUED',
  MANUAL_ISSUED = 'MANUAL_ISSUED',
  POINTS_REDEMPTION = 'POINTS_REDEMPTION',
}

export type IssuedReward = z.infer<typeof IssuedReward.schema>;
export namespace IssuedReward {
  export const _type = 'rewards.issued_reward' as const;
  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    rewardsListingId: z.string().uuid(),
    rewardsMembershipId: z.string().uuid(),
    customerId: z.string().uuid().nullish(),
    state: z.nativeEnum(IssuedRewardState),
    issuedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    redeemedAt: z.string().datetime({ offset: true }).nullable().default(null),
    expiresAt: z.string().datetime({ offset: true }).nullable().default(null),
    voidedAt: z.string().datetime({ offset: true }).nullable().default(null),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    sourceType: z.nativeEnum(IssuedRewardSourceType).nullable(),
    // The number of points paid for this issuedListing
    pointsPaidForIssuance: z.number(),
    redeemedAtOrderId: z.string().nullable().default(null), //orderId during which this was redeemed
    // Snapshotted details
    listingSnapshot: RewardsListing.schema,
  });
  export const create = (
    args: (Partial<IssuedReward> & Pick<IssuedReward, 'listingSnapshot'>) | any,
  ): IssuedReward => {
    return schema.parse({
      ...args,
      _type: _type,
      id: args.id ?? args.rewardsIssuedListingId,
      listingSnapshot: RewardsListing.create(args.listingSnapshot),
    });
  };
}

export type RewardsMembership = z.infer<typeof RewardsMembership.schema> & {
  customer?: Customers.Customer;
  issuedRewards?: IssuedReward[];
};
export namespace RewardsMembership {
  export const _type = 'rewards.rewards_member' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    customerId: z.string().uuid(),
    rewardsProgrammeId: z.string().uuid(),
    allowCommunications: z.boolean().default(false),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    lastActivityDatetime: z.string().datetime({ offset: true }),
    lifetimeSpending: z.number(),
    currentPoints: z.number(),
    cumulativePoints: z.number(),
    rewardsTier: RewardsTier.schema,
  });

  export const create = (
    args:
      | (Partial<RewardsMembership> & Pick<RewardsMembership, 'rewardsTier'>)
      | any,
  ): RewardsMembership => {
    return schema.parse({
      ...args,
      _type: _type,
      id: args.id ?? args.rewardsMembershipId,
      rewardsTier: RewardsTier.create(args.rewardsTier),
    });
  };
}

export enum CampaignRewardType {
  POINTS = 'POINTS',
  REWARDS_LISTING = 'REWARDS_LISTING',
}

export const CampaignRewardBase = z.object({
  rewardType: z.nativeEnum(CampaignRewardType),
});

export type CampaignRewardPoints = z.infer<typeof CampaignRewardPoints.schema>;
export namespace CampaignRewardPoints {
  export const schema = CampaignRewardBase.extend({
    rewardType: z
      .literal(CampaignRewardType.POINTS)
      .default(CampaignRewardType.POINTS),
    points: z.number(),
  });

  export const create = (
    args: Partial<CampaignRewardPoints>,
  ): CampaignRewardPoints => {
    return schema.parse(args);
  };
}

export type CampaignRewardPointsCreateParams = z.infer<
  typeof CampaignRewardPointsCreateParams.schema
>;
export namespace CampaignRewardPointsCreateParams {
  export const schema = CampaignRewardBase.extend({
    rewardType: z
      .literal(CampaignRewardType.POINTS)
      .default(CampaignRewardType.POINTS),
    points: z.number(),
  });
}

export type CampaignRewardListing = z.infer<
  typeof CampaignRewardListing.schema
>;
export namespace CampaignRewardListing {
  export const schema = CampaignRewardBase.extend({
    rewardType: z
      .literal(CampaignRewardType.REWARDS_LISTING)
      .default(CampaignRewardType.REWARDS_LISTING),
    rewardsListingId: z.string().uuid(),
  });

  export const create = (
    args: Partial<CampaignRewardListing>,
  ): CampaignRewardListing => {
    return schema.parse(args);
  };
}

export type CampaignRewardListingCreateParams = z.infer<
  typeof CampaignRewardListingCreateParams.schema
>;
export namespace CampaignRewardListingCreateParams {
  export const schema = CampaignRewardBase.extend({
    rewardType: z
      .literal(CampaignRewardType.REWARDS_LISTING)
      .default(CampaignRewardType.REWARDS_LISTING),
    rewardsListingId: z.string().uuid(),
  });
}

export type CampaignReward = z.infer<typeof CampaignReward.schema>;
export namespace CampaignReward {
  export const schema = z.discriminatedUnion('rewardType', [
    CampaignRewardPoints.schema,
    CampaignRewardListing.schema,
  ]);
  export const create = (args: Partial<CampaignReward>): CampaignReward => {
    switch (args.rewardType) {
      case CampaignRewardType.POINTS: {
        return CampaignRewardPoints.create(args);
      }
      case CampaignRewardType.REWARDS_LISTING: {
        return CampaignRewardListing.create(args);
      }

      default: {
        throw new Error(
          'No `rewardType` field supplied to Rewards.CampaignReward.create()',
        );
      }
    }
  };
}

export type CampaignRewardCreateParams = z.infer<
  typeof CampaignRewardCreateParams.schema
>;
export namespace CampaignRewardCreateParams {
  export const schema = z.discriminatedUnion('rewardType', [
    CampaignRewardPointsCreateParams.schema,
    CampaignRewardListingCreateParams.schema,
  ]);
}

export type TriggerFirstSignUp = z.infer<typeof TriggerFirstSignUp.schema>;
export namespace TriggerFirstSignUp {
  export const _type = 'rewards.trigger' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    rewardsTriggerType: z
      .literal(TriggerType.FIRST_SIGN_UP)
      .default(TriggerType.FIRST_SIGN_UP),
  });

  export function calculateRewardToIssue(
    campaignReward: CampaignRewardPoints,
  ): number;
  export function calculateRewardToIssue(
    campaignReward: CampaignRewardListing,
  ): string;
  export function calculateRewardToIssue(
    campaignReward: CampaignRewardPoints | CampaignRewardListing,
  ): number | string {
    switch (campaignReward.rewardType) {
      case CampaignRewardType.POINTS:
        return campaignReward.points;
      case CampaignRewardType.REWARDS_LISTING:
        return campaignReward.rewardsListingId;
      default:
        throw new Error(
          '[Rewards.TriggerFirstSignUp:calculateRewardToIssue] Impossible Error - firstSignUpCampaignReward.rewardType not valid',
        );
    }
  }
}

export type TriggerFirstSignUpCreateParams = z.infer<
  typeof TriggerFirstSignUpCreateParams.schema
>;
export namespace TriggerFirstSignUpCreateParams {
  export const schema = z.object({
    rewardsTriggerType: z
      .literal(TriggerType.FIRST_SIGN_UP)
      .default(TriggerType.FIRST_SIGN_UP),
  });
}

export type TriggerSaleAmountSpent = z.infer<
  typeof TriggerSaleAmountSpent.schema
>;
export namespace TriggerSaleAmountSpent {
  export const _type = 'rewards.trigger' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    rewardsTriggerType: z
      .literal(TriggerType.SALE_AMOUNT_SPENT)
      .default(TriggerType.SALE_AMOUNT_SPENT),
  });

  export function calculateRewardToIssue(
    campaignReward: CampaignRewardPoints,
    amountSpent: number,
    multiplier: number,
    denomination: number,
    roundingMode?: PointsRoundingMode,
  ): number;
  export function calculateRewardToIssue(
    campaignReward: CampaignRewardListing,
    amountSpent: number,
    multiplier: number,
    denomination: number,
    roundingMode?: PointsRoundingMode,
  ): string;
  export function calculateRewardToIssue(
    campaignReward: CampaignRewardPoints | CampaignRewardListing,
    amountSpent: number,
    multiplier: number,
    denomination: number,
    roundingMode: PointsRoundingMode = PointsRoundingMode.UP,
  ): number | string {
    switch (campaignReward.rewardType) {
      case CampaignRewardType.POINTS:
        const sign: 1 | -1 = amountSpent < 0 ? -1 : 1;
        const denominatedAmountSpentWithoutSign: number =
          Math.abs(amountSpent) / denomination;
        const pointsToIssuePerDenominatedAmount: number = campaignReward.points;

        let pointsToIssue: number;

        const inner: number = Number(
          (
            pointsToIssuePerDenominatedAmount *
            denominatedAmountSpentWithoutSign *
            multiplier
          ).toFixed(2),
        );
        // Fixed this to 2 decimal place to prevent 'leaking' decimals for big multipliers and certain amount spent values (eg:230)
        // TODO: switch this to a proper math library like bignumber.js

        switch (roundingMode) {
          case PointsRoundingMode.UP: {
            pointsToIssue = Math.ceil(inner) * sign;
            break;
          }
          case PointsRoundingMode.DOWN: {
            pointsToIssue = Math.floor(inner) * sign;
            break;
          }
          case PointsRoundingMode.NEAREST: {
            pointsToIssue = Math.round(inner) * sign;
            break;
          }
          default: {
            throw new Error('Unrecognized RoundingMode');
          }
        }

        return pointsToIssue;

      case CampaignRewardType.REWARDS_LISTING:
        return campaignReward.rewardsListingId;
      default:
        throw new Error(
          '[Rewards.TriggerSaleAmountSpent:calculateRewardToIssue] Impossible Error - campaignReward.rewardType not valid',
        );
    }
  }
}

export type TriggerSaleAmountSpentCreateParams = z.infer<
  typeof TriggerSaleAmountSpentCreateParams.schema
>;
export namespace TriggerSaleAmountSpentCreateParams {
  export const schema = z.object({
    rewardsTriggerType: z
      .literal(TriggerType.SALE_AMOUNT_SPENT)
      .default(TriggerType.SALE_AMOUNT_SPENT),
  });
}

export type TriggerSaleListingQuantity = z.infer<
  typeof TriggerSaleListingQuantity.schema
>;
export namespace TriggerSaleListingQuantity {
  export const _type = 'rewards.trigger' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    rewardsTriggerType: z
      .literal(TriggerType.SALE_LISTING_QUANTITY)
      .default(TriggerType.SALE_LISTING_QUANTITY),
    listingIds: z.array(z.string()), // Listing Ids that appear in the sale.
  });

  export function calculateRewardToIssue(
    campaignReward: CampaignRewardPoints,
    lineItems: Orders.OrderLineItem[] | Sales.SaleItem[],
    listingIds: string[],
  ): number;
  export function calculateRewardToIssue(
    campaignReward: CampaignRewardListing,
    lineItems: Orders.OrderLineItem[] | Sales.SaleItem[],
    listingIds: string[],
  ): string[];
  export function calculateRewardToIssue(
    campaignReward: CampaignRewardPoints | CampaignRewardListing,
    lineItems: Orders.OrderLineItem[] | Sales.SaleItem[],
    listingIds: string[],
  ): number | string[] {
    let numberOfEligibleListings: number = 0;
    for (const orderLineItem of lineItems) {
      if (
        listingIds.some((listingId) =>
          [
            orderLineItem.categoryId,
            orderLineItem.itemId,
            orderLineItem.itemVariationId,
          ].includes(listingId),
        )
      ) {
        numberOfEligibleListings += orderLineItem.quantity;
      }
    }

    switch (campaignReward.rewardType) {
      case CampaignRewardType.POINTS:
        return numberOfEligibleListings * campaignReward.points;
      case CampaignRewardType.REWARDS_LISTING:
        // e.g. [rewardsListingId, rewardsListingId, rewardsListingId, rewardsListingId]
        return Array(numberOfEligibleListings).fill(
          campaignReward.rewardsListingId,
        );
      default:
        throw new Error(
          '[Rewards.TriggerSaleListingQuantity:calclulateRewardToIssue] Impossible Error - saleListingQuantityCampaignReward.rewardType not valid',
        );
    }
  }
}

export type TriggerSaleListingQuantityCreateParams = z.infer<
  typeof TriggerSaleListingQuantityCreateParams.schema
>;
export namespace TriggerSaleListingQuantityCreateParams {
  export const schema = z.object({
    rewardsTriggerType: z
      .literal(TriggerType.SALE_LISTING_QUANTITY)
      .default(TriggerType.SALE_LISTING_QUANTITY),
    listingIds: z.array(z.string()), // Listing Ids that appear in the sale.
  });
}

export namespace TriggerMemberBirthday {
  export const _type = 'rewards.trigger' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    rewardsTriggerType: z
      .literal(TriggerType.BIRTHDAY)
      .default(TriggerType.BIRTHDAY),
    triggerWindowType: z.nativeEnum(TriggerWindowType),
  });

  export type TriggerWindow = {
    startDate: DateTime;
    endDate: DateTime;
  };

  export function getTriggerWindow(args: {
    anchorDate: DateTime;
    triggerWindowType: TriggerWindowType;
    issuingDate: DateTime;
  }): TriggerWindow {
    const { anchorDate, triggerWindowType: windowType } = args;

    let startDate: DateTime;
    let endDate: DateTime;
    switch (windowType) {
      case TriggerWindowType.MONTH: {
        startDate = anchorDate
          .set({ year: args.issuingDate.year })
          .startOf('month');
        endDate = anchorDate
          .set({ year: args.issuingDate.year })
          .endOf('month');
        break;
      }
      case TriggerWindowType.WEEK: {
        startDate = anchorDate
          .set({ year: args.issuingDate.year })
          .startOf('week');
        endDate = anchorDate.set({ year: args.issuingDate.year }).endOf('week');
        break;
      }
      case TriggerWindowType.DAY: {
        startDate = anchorDate
          .set({ year: args.issuingDate.year })
          .startOf('day');
        endDate = anchorDate.set({ year: args.issuingDate.year }).endOf('day');
        break;
      }
      default:
        throw new Error(
          `[Rewards.TriggerMemberBirthday:getTriggerWindow] Invalid trigger window type: ${windowType}`,
        );
    }

    const triggerWindow: TriggerWindow = {
      startDate: startDate,
      endDate: endDate,
    };
    return triggerWindow;
  }

  export function calculateRewardToIssue(
    campaignReward: CampaignRewardPoints,
  ): number;
  export function calculateRewardToIssue(
    campaignReward: CampaignRewardListing,
  ): string;
  export function calculateRewardToIssue(
    campaignReward: CampaignRewardPoints | CampaignRewardListing,
  ): number | string {
    switch (campaignReward.rewardType) {
      case CampaignRewardType.POINTS:
        return campaignReward.points;
      case CampaignRewardType.REWARDS_LISTING:
        return campaignReward.rewardsListingId;
      default:
        throw new Error(
          '[Rewards.TriggerMemberBirthday:calculateRewardToIssue] Impossible Error - campaignReward.rewardType not valid',
        );
    }
  }
}

export type TriggerMemberBirthdayCreateParams = z.infer<
  typeof TriggerMemberBirthdayCreateParams.schema
>;
export namespace TriggerMemberBirthdayCreateParams {
  export const schema = z.object({
    rewardsTriggerType: z
      .literal(TriggerType.BIRTHDAY)
      .default(TriggerType.BIRTHDAY),
    triggerWindowType: z.nativeEnum(TriggerWindowType),
  });
}

export type Trigger = z.infer<typeof Trigger.schema>;
export namespace Trigger {
  export const _type = 'rewards.trigger' as const;

  export const schema = z.discriminatedUnion('rewardsTriggerType', [
    TriggerFirstSignUp.schema,
    TriggerSaleAmountSpent.schema,
    TriggerSaleListingQuantity.schema,
    TriggerMemberBirthday.schema,
  ]);

  export const create = (args: Partial<Trigger> | any): Trigger => {
    return schema.parse({
      ...args,
      _type: _type,
      id: args.id ?? args.rewardsTriggerId,
    });
  };
}

export type TriggerCreateParams = z.infer<typeof TriggerCreateParams.schema>;
export namespace TriggerCreateParams {
  export const schema = z.discriminatedUnion('rewardsTriggerType', [
    TriggerFirstSignUpCreateParams.schema,
    TriggerSaleAmountSpentCreateParams.schema,
    TriggerSaleListingQuantityCreateParams.schema,
    TriggerMemberBirthdayCreateParams.schema,
  ]);
}

export type Campaign = z.infer<typeof Campaign.schema> & {
  rewardsListing?: RewardsListing;
  triggerAtLocations?: Sellers.Location[];
  triggerIncludeCustomerSets?: CustomerSets.CustomerSet[];
};
export namespace Campaign {
  export const _type = 'rewards.campaign' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    rewardsProgrammeId: z.string().uuid(),
    name: z.string().nullable(),
    trigger: Trigger.schema,
    reward: CampaignReward.schema,
    presentAtAllLocations: z.boolean().default(true),
    sellerLocationIds: z.array(z.string()).default([]),
    shouldTargetAllMembers: z.boolean().default(true),
    includeCustomerSetIds: z.array(z.string()).default([]),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()), //For backwards compatibility
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()), //For backwards compatibility
    isDeleted: z.boolean().default(false),
  });

  export const create = (
    args: (Partial<Campaign> & Pick<Campaign, 'trigger' | 'reward'>) | any,
  ): Campaign => {
    return schema.parse({
      ...args,
      _type: _type,
      id: args.id ?? args.rewardsCampaignId,
      trigger: Trigger.create(args.trigger),
      reward: args.reward,
    });
  };
}

export type CampaignCreateParams = z.infer<typeof CampaignCreateParams.schema>;
export namespace CampaignCreateParams {
  export const schema = z.object({
    name: z.string().nullable().default(null),
    rewardsProgrammeId: z.string().uuid(),
    trigger: TriggerCreateParams.schema,
    reward: CampaignRewardCreateParams.schema,
    presentAtAllLocations: z.boolean().default(true),
    sellerLocationIds: z.array(z.string()).default([]),
    shouldTargetAllMembers: z.boolean().default(true),
    includeCustomerSetIds: z.array(z.string()).default([]),
  });
}

// NOTE: Only allow updating name for now
export type CampaignUpdateParams = z.infer<typeof CampaignUpdateParams.schema>;
export namespace CampaignUpdateParams {
  export const schema = z.object({
    name: z.string().nullable().default(null),
  });
}

export enum PointTransactionType {
  EXPIRY = 'EXPIRY',
  MANUAL_ISSUED = 'MANUAL_ISSUED',
  CAMPAIGN_ISSUED = 'CAMPAIGN_ISSUED',
  REWARD_REDEMPTION = 'REWARD_REDEMPTION',
  VOIDED = 'VOIDED',
}

export type PointTransaction = z.infer<typeof PointTransaction.schema>;
export namespace PointTransaction {
  export const _type = 'rewards.point_transaction' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    rewardsMembershipId: z.string().uuid(),
    customerId: z.string().uuid().nullish(),
    issuedAtOrderId: z.string().uuid().nullable().default(null),
    issuedFromCampaignId: z.string().uuid().nullable().default(null),
    rewardsIssuedListingId: z.string().uuid().nullable().default(null),
    pointsAwarded: z.number(),
    roundingApplied: z.string().nullable(),
    type: z.nativeEnum(PointTransactionType).nullable(),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (
    args: Partial<PointTransaction> | any,
  ): PointTransaction => {
    return schema.parse({
      ...args,
      _type: _type,
      id: args.id ?? args.rewardsPointTransactionId,
    });
  };
}

//Snapshot of points expiry setting
//TODO - Have Programme compose PointsExpiryPolicy
export type PointsExpiryPolicy = z.infer<typeof PointsExpiryPolicy.schema>;
export namespace PointsExpiryPolicy {
  export const _type = 'rewards.points_expiry_policy' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(), //point to rewardsProgrammeId for now
    pointsExpiryWindowMonths: z.number(),
    isPointsExpiryEnabled: z.boolean(),
  });

  export const create = (
    args: Partial<PointsExpiryPolicy>,
  ): PointsExpiryPolicy => {
    return schema.parse({
      ...args,
      _type: _type,
    });
  };
}

export type MembershipCreatedEvent = z.infer<
  typeof MembershipCreatedEvent.schema
>;
export namespace MembershipCreatedEvent {
  export const TYPE = 'rewards.membership.created';
  export const CURRENT_VERSION = '2021-11-26';

  export namespace Payload {
    export const schema = z.object({
      rewardsMembership: RewardsMembership.schema,
    });
  }

  export const schema = z.object({
    id: z.string().uuid(),
    type: z
      .literal(MembershipCreatedEvent.TYPE)
      .default(MembershipCreatedEvent.TYPE),
    version: z
      .literal(MembershipCreatedEvent.CURRENT_VERSION)
      .default(MembershipCreatedEvent.CURRENT_VERSION),
    payload: MembershipCreatedEvent.Payload.schema,
  });

  export const create = ({
    rewardsMembership,
  }: {
    rewardsMembership: RewardsMembership;
  }): MembershipCreatedEvent => {
    return {
      id: uuidv4(),
      type: TYPE,
      version: CURRENT_VERSION,
      payload: {
        rewardsMembership: rewardsMembership,
      },
    };
  };
}

export interface PointTransactionCreatedEvent {
  id: string;
  type: typeof PointTransactionCreatedEvent.TYPE;
  version: typeof PointTransactionCreatedEvent.CURRENT_VERSION;
  payload: PointTransactionCreatedEvent.Payload;
}

export namespace PointTransactionCreatedEvent {
  export const TYPE = 'rewards.point_transaction.created';
  export const CURRENT_VERSION = '2021-11-26';

  export interface Payload {
    rewardsPointTransaction: PointTransaction;
  }

  export const create = ({
    rewardsPointTransaction,
  }: {
    rewardsPointTransaction: PointTransaction;
  }): PointTransactionCreatedEvent => {
    return {
      id: uuidv4(),
      type: TYPE,
      version: CURRENT_VERSION,
      payload: {
        rewardsPointTransaction: rewardsPointTransaction,
      },
    };
  };
}

export type PointsExpiryPolicyUpdatedEvent = z.infer<
  typeof PointsExpiryPolicyUpdatedEvent.schema
>;
export namespace PointsExpiryPolicyUpdatedEvent {
  export const _type = 'rewards.rewards_programme.expiry_policy_updated';
  export const CURRENT_VERSION = '2023-05-11';

  export const schema = z.object({
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    type: z.literal(_type).default(_type),
    version: z.string().default(CURRENT_VERSION),
    payload: z.object({
      rewardsProgrammeId: z.string().uuid(),
      current: PointsExpiryPolicy.schema,
      incoming: PointsExpiryPolicy.schema,
    }),
  });

  export const create = (args: {
    rewardsProgrammeId: string;
    current: Record<string, any>; // TODO: Fix types
    incoming: Record<string, any>; // TODO: Fix types
  }): PointsExpiryPolicyUpdatedEvent => {
    return schema.parse({
      payload: { ...args },
      type: _type,
    });
  };
}

export type MembershipPointsExpiredEvent = z.infer<
  typeof MembershipPointsExpiredEvent.schema
>;
export namespace MembershipPointsExpiredEvent {
  export const _type = 'rewards.membership.points_expired';
  export const CURRENT_VERSION = '2023-05-11';

  export const schema = z.object({
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    type: z.literal(_type).default(_type),
    version: z.string().default(CURRENT_VERSION),
    payload: z.object({
      rewardsMembershipId: z.string().uuid(),
      customerId: z.string().uuid(),
      rewardsPointTransactionId: z.string().uuid(),
      lastActivityDatetimeOnExpiry: z.string().datetime({ offset: true }),
      pointsExpiryWindowApplied: z.number(),
    }),
  });
  export const create = (args: {
    rewardsMembershipId: string;
    customerId: string;
    rewardsPointTransactionId: string;
    lastActivityDatetimeOnExpiry: string;
    pointsExpiryWindowApplied: number;
  }): MembershipPointsExpiredEvent => {
    return schema.parse({
      payload: { ...args },
      type: _type,
    });
  };
}

export type MembershipPointsRenewalEvent = z.infer<
  typeof MembershipPointsRenewalEvent.schema
>;
export namespace MembershipPointsRenewalEvent {
  export const _type = 'rewards.membership.points_renewal';
  export const CURRENT_VERSION = '2023-05-11';

  export const schema = z.object({
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    type: z.literal(_type).default(_type),
    version: z.string().default(CURRENT_VERSION),
    payload: z.object({
      rewardsMembershipId: z.string().uuid(),
      customerId: z.string().uuid(),
      rewardsProgrammeId: z.string().uuid(),
      renewalActivityType: z.string(),
      renewalActivityId: z.string().uuid(),
      oldLastActivityDateTime: z.string().datetime({ offset: true }),
      newLastActivityDateTime: z.string().datetime({ offset: true }),
    }),
  });
  export const create = (args: {
    rewardsMembershipId: string;
    customerId: string;
    rewardsProgrammeId: string;
    renewalActivityType: string;
    renewalActivityId: string;
    oldLastActivityDateTime: string;
    newLastActivityDateTime: string;
  }): MembershipPointsRenewalEvent => {
    return schema.parse({
      payload: { ...args },
      type: _type,
    });
  };
}

export type CheckRewardsPointsExpiryCommand = z.infer<
  typeof CheckRewardsPointsExpiryCommand.schema
>;
export namespace CheckRewardsPointsExpiryCommand {
  export const _type = 'rewards.check_rewards_point_expiry_command';
  export const CURRENT_VERSION = '2023-05-11';

  export const schema = z.object({
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    type: z.literal(_type).default(_type),
    version: z.string().default(CURRENT_VERSION),
    payload: z.object({}),
  });
  export const create = (): CheckRewardsPointsExpiryCommand => {
    return schema.parse({
      type: _type,
      payload: {},
    });
  };
}

export type CheckIssuedRewardsExpiryCommand = z.infer<
  typeof CheckIssuedRewardsExpiryCommand.schema
>;
export namespace CheckIssuedRewardsExpiryCommand {
  export const _type = 'rewards.check_issued_rewards_expiry_command';
  export const CURRENT_VERSION = '2023-05-11';

  export const schema = z.object({
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    type: z.literal(_type).default(_type),
    version: z.string().default(CURRENT_VERSION),
    payload: z.object({}),
  });
  export const create = (): CheckIssuedRewardsExpiryCommand => {
    return schema.parse({
      type: _type,
      payload: {},
    });
  };
}

export type CheckRewardsCampaignBirthdayTriggerCommand = z.infer<
  typeof CheckRewardsCampaignBirthdayTriggerCommand.schema
>;
export namespace CheckRewardsCampaignBirthdayTriggerCommand {
  export const _type =
    'rewards.check_rewards_campaign_birthday_trigger_command';
  export const CURRENT_VERSION = '2023-08-01';

  export const schema = z.object({
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    type: z.literal(_type).default(_type),
    version: z.string().default(CURRENT_VERSION),
    payload: z.object({
      rewardsProgrammeId: z.string().uuid(),
      rewardsCampaignId: z.string().uuid(),
    }),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });
  export const create = ({
    rewardsProgrammeId,
    rewardsCampaignId,
  }: {
    rewardsProgrammeId: string;
    rewardsCampaignId: string;
  }): CheckRewardsCampaignBirthdayTriggerCommand => {
    return schema.parse({
      type: _type,
      payload: {
        rewardsProgrammeId: rewardsProgrammeId,
        rewardsCampaignId: rewardsCampaignId,
      },
    });
  };
}

export const CalculatedCampaignRewardBase = z.object({
  rewardType: z.nativeEnum(CampaignRewardType),
  rewardsCampaignId: z.string().uuid(),
});

export type CalculatedCampaignRewardsPoints = z.infer<
  typeof CalculatedCampaignRewardsPoints.schema
>;
export namespace CalculatedCampaignRewardsPoints {
  export const schema = CalculatedCampaignRewardBase.extend({
    rewardType: z
      .literal(CampaignRewardType.POINTS)
      .default(CampaignRewardType.POINTS),
    points: z.number(),
  });

  export const create = (
    args: Partial<CalculatedCampaignRewardsPoints>,
  ): CalculatedCampaignRewardsPoints => {
    return schema.parse(args);
  };
}

export type CalculatedCampaignRewardsListing = z.infer<
  typeof CalculatedCampaignRewardsListing.schema
>;
export namespace CalculatedCampaignRewardsListing {
  export const schema = CalculatedCampaignRewardBase.extend({
    rewardType: z
      .literal(CampaignRewardType.REWARDS_LISTING)
      .default(CampaignRewardType.REWARDS_LISTING),
    rewardsListingId: z.string().uuid(),
  });

  export const create = (
    args: Partial<CalculatedCampaignRewardsListing>,
  ): CalculatedCampaignRewardsListing => {
    return schema.parse(args);
  };
}

export type CalculatedCampaignRewardsListingCreateParams = z.infer<
  typeof CalculatedCampaignRewardsListingCreateParams.schema
>;
export namespace CalculatedCampaignRewardsListingCreateParams {
  export const schema = CalculatedCampaignRewardBase.extend({
    rewardType: z
      .literal(CampaignRewardType.REWARDS_LISTING)
      .default(CampaignRewardType.REWARDS_LISTING),
    rewardsListingId: z.string().uuid(),
  });
}

export type CalculatedCampaignReward = z.infer<
  typeof CalculatedCampaignReward.schema
>;
export namespace CalculatedCampaignReward {
  export const schema = z.discriminatedUnion('rewardType', [
    CalculatedCampaignRewardsPoints.schema,
    CalculatedCampaignRewardsListing.schema,
  ]);
  export const create = (
    args: Partial<CalculatedCampaignReward>,
  ): CalculatedCampaignReward => {
    switch (args.rewardType) {
      case CampaignRewardType.POINTS: {
        return CalculatedCampaignRewardsPoints.create(args);
      }
      case CampaignRewardType.REWARDS_LISTING: {
        return CalculatedCampaignRewardsListing.create(args);
      }

      default: {
        throw new Error(
          'No `rewardType` field supplied to Rewards.CalculatedCampaignReward.create()',
        );
      }
    }
  };
}
