/**
 * RadioGroup component from shadcn/ui
 * @see https://ui.shadcn.com/docs/components/radio-group
 *
 * Edits:
 * 1. Added a custom <RadioIndicator> component that behaves like a <Checkbox>, for implementing custom controlled UIs
 */
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { Circle } from 'lucide-react';
import * as React from 'react';

import { cn } from '../../lib/utils';

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn('grid gap-2', className)}
      {...props}
      ref={ref}
    />
  );
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(
        'border-primary/50 text-primary ring-offset-background focus-visible:ring-ring aspect-square h-4 w-4 rounded-full border focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
        className,
      )}
      {...props}>
      <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
        <Circle className="h-2.5 w-2.5 fill-current text-current" />
      </RadioGroupPrimitive.Indicator>
    </RadioGroupPrimitive.Item>
  );
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

interface RadioIndicatorProps {
  className?: string;
  isChecked: boolean;
  isDisabled?: boolean;
}

const RadioIndicator: React.FC<RadioIndicatorProps> = ({
  className,
  isChecked,
  isDisabled,
}) => {
  return (
    <div
      className={cn(
        'border-primary/50 ring-offset-background focus-visible:ring-ring flex aspect-square h-4 w-4 items-center justify-center rounded-full border focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2',
        isDisabled && 'disabled:cursor-not-allowed disabled:opacity-50',
        className,
      )}
      role="radio"
      aria-checked={isChecked}
      aria-disabled={isDisabled}>
      {isChecked && <div className="h-2.5 w-2.5 rounded-full bg-current" />}
    </div>
  );
};

export { RadioGroup, RadioGroupItem, RadioIndicator };
