import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { Listings, Rewards } from '@waffle/common/src/models';
import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  HBox,
  Separator,
  Text,
  useToast,
} from '@waffle/ui-web';
import {
  ClockIcon,
  DollarSignIcon,
  PercentIcon,
  ShoppingBasketIcon,
  StoreIcon,
} from '@waffle/ui-web/icons';

import {
  useRewardsListingsQuery,
  useRewardsProgrammeQuery,
} from '../../../services/rewardsService';
import { useSubdomainSellerQuery } from '../../../services/sellersService';
import buyersApiClient from '../../../utils/ApiService';

export const ShopRewardDialog = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { data: seller } = useSubdomainSellerQuery();
  const { data: rewardsProgramme } = useRewardsProgrammeQuery();

  const { rewardsListingId } = useParams();
  // TODO: Implement proper query to retrieve the RewardsListing
  const { data: rewardsListings } = useRewardsListingsQuery();
  const rewardsListing = rewardsListings.find(
    (listing) => listing.id === rewardsListingId,
  );

  const { mutate: claimReward, isPending: isClaimRewardPending } = useMutation({
    mutationFn: async ({ rewardsListingId }: { rewardsListingId: string }) => {
      const issuedReward = (
        await buyersApiClient.request({
          method: 'POST',
          url: `/rewards_listings/${rewardsListingId}/issue`,
          params: { sellerId: seller.id },
        })
      ).issuedReward;

      return issuedReward;
    },
    onSuccess: (issuedReward: Rewards.IssuedReward) => {
      queryClient.invalidateQueries({ queryKey: ['/rewards_listings'] });
      queryClient.invalidateQueries({ queryKey: ['/issued_rewards'] });
      queryClient.invalidateQueries({ queryKey: ['/rewards_memberships'] });
    },
  });

  const handleClose = () => {
    navigate('/rewards');
  };

  return (
    <Dialog open={true} onOpenChange={handleClose}>
      <DialogContent>
        {/* Paper UI */}
        <DialogBody className={'bg-gray-100'}>
          <Box className={'drop-shadow-xl'}>
            <Box
              className={'rounded-lg bg-white'}
              style={{
                WebkitMaskImage: `radial-gradient(circle at right 10px bottom 80px, transparent 10px, red 10.5px)`,
                WebkitMaskPosition: 10,
              }}>
              <Box className={'gap-8 p-4'}>
                {/* Header */}
                <Box>
                  <HBox className={'items-end gap-4'}>
                    {/* Icon / Image */}
                    <Box
                      className={
                        'h-[80px] w-[80px] items-center justify-center rounded-lg shadow-md'
                      }>
                      {rewardsListing.rewardsListingType ===
                      Rewards.ListingType.ITEM ? (
                        <ShoppingBasketIcon className={'text-gray-500'} />
                      ) : (
                        <PercentIcon className={'text-gray-500'} />
                      )}
                    </Box>
                    <Box className={'items-start'}>
                      <Badge>
                        {rewardsListing.rewardsListingType ===
                        Listings.ListingType.ITEM
                          ? 'Item'
                          : 'Discount'}
                      </Badge>
                      <Text className={'text-md line-clamp-1 font-semibold'}>
                        {rewardsListing.name}
                      </Text>
                      <Text variant={'muted'}>
                        {rewardsListing.description}
                      </Text>
                    </Box>
                  </HBox>
                </Box>

                {/* Issuance Details */}
                <Box className={'gap-4'}>
                  <HBox className={'gap-2'}>
                    <DollarSignIcon />
                    <Text variant={'label'}>
                      {rewardsListing.pointsRequired}{' '}
                      {rewardsProgramme.pointName}
                    </Text>
                  </HBox>
                  <HBox className={'gap-2'}>
                    <ClockIcon />
                    <Text variant={'label'}>Does not expire</Text>
                  </HBox>
                  <HBox className={'gap-2'}>
                    <StoreIcon />
                    {rewardsListing.presentAtAllLocations ? (
                      <Box>
                        <Text variant={'label'}>
                          Redeemable at all {seller.name} store(s)
                        </Text>
                      </Box>
                    ) : (
                      <Box>
                        <Text variant={'label'}>
                          Redeemable at {rewardsListing.sellerLocations.length}{' '}
                          {seller.name} store(s)
                        </Text>
                        <ul>
                          {rewardsListing.sellerLocations.map((location) => {
                            return (
                              <li key={location.id}>
                                <Text variant={'muted'}>• {location.name}</Text>
                              </li>
                            );
                          })}
                        </ul>
                      </Box>
                    )}
                  </HBox>
                </Box>
              </Box>

              <Separator className={'border-dashed'} />

              {/* Claim Button */}
              <Box className={'h-[80px] items-center justify-center p-4'}>
                <Button
                  className={'w-full'}
                  isLoading={isClaimRewardPending}
                  onPress={() =>
                    claimReward(
                      { rewardsListingId: rewardsListing.id },
                      {
                        onSuccess: (issuedReward: Rewards.IssuedReward) => {
                          navigate(`/rewards/inventory/${issuedReward.id}`);
                          toast.show({
                            status: 'success',
                            title: `${rewardsListing.name} claimed`,
                          });
                        },
                      },
                    )
                  }>
                  Claim for {rewardsListing.pointsRequired}{' '}
                  {rewardsProgramme?.pointName}
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogBody>
      </DialogContent>
    </Dialog>
  );
};
