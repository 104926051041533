import { useWindowVirtualizer } from '@tanstack/react-virtual';
import * as React from 'react';

import { cn } from '../../lib/utils';
import { Spinner } from './spinner';

export type RenderItemInfo<T> = {
  item: T;
  index: number;
};

export interface VirtualListProps<T> {
  data: T[];
  renderItem: (info: RenderItemInfo<T>) => React.ReactNode;
  estimateSize?: (index: number) => number;
  overscan?: number;
  className?: string;
  itemClassName?: string;
  keyExtractor?: (item: T, index: number) => string;
  onEndReached?: () => void;
  onEndReachedThreshold?: number;
  ListEmptyComponent?: React.ReactNode;
  isLoadingMore?: boolean;
  onViewableItemsChanged?: (viewableItems: RenderItemInfo<T>[]) => void;
  scrollToFn?: (index: number) => void;
}

const DEFAULT_ESTIMATE_SIZE = 50;
const DEFAULT_OVERSCAN = 10;
const DEFAULT_THRESHOLD = 0.01;

export const VirtualList = React.forwardRef<
  HTMLDivElement,
  VirtualListProps<any>
>(function VirtualList<T>(
  {
    data,
    renderItem,
    estimateSize = () => DEFAULT_ESTIMATE_SIZE,
    overscan = DEFAULT_OVERSCAN,
    className,
    itemClassName,
    keyExtractor,
    onEndReached,
    onEndReachedThreshold = DEFAULT_THRESHOLD,
    ListEmptyComponent,
    isLoadingMore = false,
    onViewableItemsChanged,
  }: VirtualListProps<T>,
  ref,
) {
  const parentRef = React.useRef<HTMLDivElement>(null);
  const [canLoadMore, setCanLoadMore] = React.useState(true);
  const [topItemIndex, setTopItemIndex] = React.useState<number | null>(null);

  // Update virtualizer to use window scrolling
  const virtualizer = useWindowVirtualizer({
    count: data.length,
    estimateSize: estimateSize,
    overscan: overscan,
  });

  // Expose virtualizer.scrollToIndex method
  React.useImperativeHandle(ref, () => ({
    scrollToIndex: virtualizer.scrollToIndex,
  }));

  // Reset canLoadMore when data length changes (new data loaded)
  React.useEffect(() => {
    setCanLoadMore(true);
  }, [data]);

  // Handle end reached detection
  React.useEffect(() => {
    if (!onEndReached || isLoadingMore) return;

    const handleScroll = () => {
      if (!canLoadMore || isLoadingMore) return;

      const windowHeight = window.innerHeight;
      const scrollTop = window.scrollY;
      const scrollHeight = document.documentElement.scrollHeight;

      const distanceFromBottom = scrollHeight - (scrollTop + windowHeight);
      const threshold = scrollHeight * onEndReachedThreshold;

      if (distanceFromBottom < threshold) {
        setCanLoadMore(false);
        onEndReached();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [onEndReached, onEndReachedThreshold, canLoadMore, isLoadingMore]);

  // Handle viewable items change
  React.useEffect(() => {
    if (!onViewableItemsChanged) return;

    const handleScroll = () => {
      const virtualItems = virtualizer.getVirtualItems();
      const firstVisibleItem = virtualItems.find(
        (item) => item.start >= window.scrollY,
      );

      if (firstVisibleItem && firstVisibleItem.index !== topItemIndex) {
        setTopItemIndex(firstVisibleItem.index);
        onViewableItemsChanged([
          { item: data[firstVisibleItem.index], index: firstVisibleItem.index },
        ]);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [onViewableItemsChanged, data, topItemIndex]);

  // Render empty state if no data
  if (data.length === 0 && ListEmptyComponent) {
    return (
      <div ref={parentRef} className={className}>
        {ListEmptyComponent}
      </div>
    );
  }

  return (
    <div ref={parentRef} className={cn(className)}>
      <div
        style={{
          height: `${virtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}>
        {virtualizer.getVirtualItems().map((virtualRow) => {
          const item = data[virtualRow.index];
          const key = keyExtractor
            ? keyExtractor(item, virtualRow.index)
            : virtualRow.key;

          return (
            <div
              key={key}
              data-index={virtualRow.index}
              className={cn('absolute left-0 w-full', itemClassName)}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: `${virtualRow.size}px`,
                transform: `translateY(${
                  virtualRow.start - virtualizer.options.scrollMargin
                }px)`,
              }}>
              {renderItem({ item, index: virtualRow.index })}
            </div>
          );
        })}
      </div>
      {isLoadingMore && (
        <div className="flex h-[48px] justify-center">
          <Spinner />
        </div>
      )}
    </div>
  );
});
