import React from 'react';
import { Outlet } from 'react-router-dom';

import {
  Box,
  Toaster,
  WaffleErrorBoundary,
  WaffleSuspenseBoundary,
} from '@waffle/ui-web';

export const AppLayout = () => {
  return (
    <>
      <Box
        className={
          'w-[calc(100dvw - calc(100dvw - 100%))] bg-background h-full min-h-[100dvh]'
        }>
        <WaffleErrorBoundary>
          <WaffleSuspenseBoundary>
            <Outlet />
          </WaffleSuspenseBoundary>
        </WaffleErrorBoundary>
      </Box>

      {/* Provider for Toasts */}
      <Toaster closeButton />
    </>
  );
};
