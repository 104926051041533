import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { Customers } from '@waffle/common/src/models';
import { PoweredByWaffleImage } from '@waffle/components-web';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  ExternalLink,
  HBox,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Pressable,
  Separator,
  Text,
} from '@waffle/ui-web';
import { CheckIcon, XIcon } from '@waffle/ui-web/icons';

import { useBuyerUserQuery } from '../../../services/buyersService';
import { useSubdomainSellerQuery } from '../../../services/sellersService';
import buyersApiClient from '../../../utils/ApiService';

const PrivacyConsentPage = () => {
  const queryClient = useQueryClient();
  const { data: buyerUser } = useBuyerUserQuery();
  const { data: seller } = useSubdomainSellerQuery();

  const [showModal, setShowModal] = useState(false);
  const [didConsentShareProfile, setDidConsentShareProfile] = useState(true);
  const [didConsentMarketing, setDidConsentMarketing] = useState(true);

  const { mutate: setCustomerConsent, isPending: isSetCustomerConsentPending } =
    useMutation({
      mutationFn: async ({
        sellerId,
        didConsentShareProfile,
        didConsentMarketing,
      }: {
        sellerId: string;
        didConsentShareProfile: boolean;
        didConsentMarketing: boolean;
      }) => {
        let customer: Customers.Customer | undefined = undefined;

        // 1. Retrieve any existing Customer for this Buyer-Seller combination
        const resCustomers: Customers.Customer[] = (
          await buyersApiClient.request({
            method: 'GET',
            url: '/customers',
            params: { sellerId: seller?.id },
            validateStatus: (status) => {
              if (status >= 400 && status !== 404) {
                return false;
              }
              return true;
            },
          })
        ).customers;

        if (resCustomers.length === 1) {
          customer = resCustomers[0];
        }

        if (!customer) {
          customer = (
            await buyersApiClient.request({
              method: 'POST',
              url: '/customers',
              data: {
                sellerId: sellerId,
              },
            })
          ).customer;
        }

        if (!customer) {
          throw new Error('Failed to create Customer');
        }

        // Set Privacy Policy consent
        await buyersApiClient.request({
          method: 'POST',
          url: `/customers/${customer.id}/accept_privacy_policy`,
        });

        // Set share profile consent
        await buyersApiClient.request({
          method: 'POST',
          url: `/customers/${customer.id}/set_share_profile_consent`,
          data: {
            didConsentShareProfile: didConsentShareProfile,
          },
        });

        // Set marketing consent
        await buyersApiClient.request({
          method: 'POST',
          url: `/customers/${customer.id}/set_marketing_consent`,
          data: {
            didConsentMarketing: didConsentMarketing,
          },
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['/customers'] });
      },
    });

  if (!buyerUser.mobileNumber) {
    return (
      <Box className={'h-screen items-center bg-gray-50 py-8'}>
        <Text>Invalid Profile! Missing Phone Number. Please Contact Us.</Text>
      </Box>
    );
  }

  return (
    <>
      <Box className={'flex-1 bg-gray-50'}>
        <Container className={'items-center py-8'}>
          <Box className={'gap-8'}>
            <Card className={'w-auto max-w-none'}>
              <CardHeader>
                <Text variant={'h2'}>Data & Privacy</Text>
              </CardHeader>

              <Separator />

              <CardContent>
                <HBox className={'gap-4'}>
                  <Box className={'flex-1 gap-2'}>
                    <HBox className={'items-baseline gap-2'}>
                      <Text variant={'label'}>Share Required Data</Text>
                      <Popover>
                        <PopoverTrigger asChild>
                          <Pressable>
                            <Text variant={'muted'}>(?)</Text>
                          </Pressable>
                        </PopoverTrigger>
                        <PopoverContent>
                          <Text variant={'muted'}>
                            Required data such as your mobile number and email
                            address will always be shared with {seller.name} so
                            that they can provide you with core services.
                          </Text>
                        </PopoverContent>
                      </Popover>
                    </HBox>

                    <Box className={'gap-1'}>
                      {!!buyerUser.mobileNumber && (
                        <Box>
                          <Text variant={'muted'}>Mobile number</Text>
                          <Text variant={'label'}>
                            {buyerUser.mobileNumber}
                          </Text>
                        </Box>
                      )}
                      {!!buyerUser.emailAddress && (
                        <Box>
                          <Text variant={'muted'}>Email address</Text>
                          <Text variant={'label'}>
                            {buyerUser.emailAddress}
                          </Text>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Checkbox isChecked={true} isDisabled={true} />
                </HBox>
              </CardContent>

              <Separator />

              <CardContent>
                <HBox className={'gap-4'}>
                  <Box className={'flex-1 gap-2'}>
                    <HBox className={'items-baseline gap-2'}>
                      <Text variant={'label'}>Share Additional Data</Text>
                      <Popover>
                        <PopoverTrigger asChild>
                          <Pressable>
                            <Text variant={'muted'}>(?)</Text>
                          </Pressable>
                        </PopoverTrigger>
                        <PopoverContent>
                          <Text variant={'muted'}>
                            {`Additional data, such as your name and birthday, helps ${seller.name} provide you with more personalised services and rewards.`}
                          </Text>
                        </PopoverContent>
                      </Popover>
                    </HBox>

                    <Box>
                      {!buyerUser.dateOfBirth &&
                      !buyerUser.familyName &&
                      !buyerUser.givenName ? (
                        <Text variant={'muted'}>
                          Your profile is currently empty but details that you
                          add to your profile will be shared with {seller.name}
                        </Text>
                      ) : (
                        <Box>
                          {!!buyerUser.givenName && (
                            <Box>
                              <Text variant={'muted'}>Given name</Text>
                              <Text variant={'label'}>
                                {buyerUser.givenName}
                              </Text>
                            </Box>
                          )}
                          {!!buyerUser.familyName && (
                            <Box>
                              <Text variant={'muted'}>Family name</Text>
                              <Text variant={'label'}>
                                {buyerUser.familyName}
                              </Text>
                            </Box>
                          )}
                          {!!buyerUser.dateOfBirth && (
                            <Box>
                              <Text variant={'muted'}>Birth date</Text>
                              <Text variant={'label'}>
                                {DateTime.fromISO(
                                  buyerUser.dateOfBirth,
                                ).toLocaleString(DateTime.DATE_MED)}
                              </Text>
                            </Box>
                          )}
                          <Text variant={'muted'}>
                            Profile last updated:{' '}
                            {DateTime.fromISO(
                              buyerUser.updatedAt,
                            ).toLocaleString(DateTime.DATE_MED)}
                          </Text>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Checkbox
                    isChecked={didConsentShareProfile}
                    onCheckedChange={() =>
                      setDidConsentShareProfile(!didConsentShareProfile)
                    }
                  />
                </HBox>
              </CardContent>
            </Card>

            {/* Continue button section */}
            <Box className={'gap-2'}>
              {/* Consent marketing */}
              <HBox className={'items-center gap-2'}>
                <Checkbox
                  id={'consent-marketing-checkbox'}
                  isChecked={didConsentMarketing}
                  onCheckedChange={() =>
                    setDidConsentMarketing(!didConsentMarketing)
                  }
                />
                <Label htmlFor={'consent-marketing-checkbox'} variant={'label'}>
                  I want to receive offers and updates from {seller.name}.
                </Label>
              </HBox>

              {/* Continue button */}
              <Button
                onPress={() => {
                  if (!didConsentShareProfile) {
                    setShowModal(true);
                    return;
                  }

                  setCustomerConsent({
                    sellerId: seller.id,
                    didConsentShareProfile: didConsentShareProfile,
                    didConsentMarketing: didConsentMarketing,
                  });
                }}
                isLoading={isSetCustomerConsentPending}>
                Continue
              </Button>

              {/* Link to privacy policy */}
              <Text variant={'muted-small'} className={'text-center'}>
                {`By continuing, you agree to the `}
                <ExternalLink
                  shouldOpenInNewTab
                  shouldIncludeIcon
                  href={
                    seller.latestSellerPrivacyPolicy?.file?.url ??
                    `https://www.wafflepos.com/legal/seller-buyer-privacy-policy?seller=${seller.slug}`
                  }>{`${seller.name} Privacy Policy`}</ExternalLink>
                {`.`}
              </Text>
            </Box>
          </Box>

          <ExternalLink
            href={`https://www.wafflepos.com/`}
            shouldOpenInNewTab
            className={'fixed bottom-0 mx-auto p-4'}>
            <PoweredByWaffleImage />
          </ExternalLink>
        </Container>
      </Box>

      <Dialog open={showModal} onOpenChange={setShowModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Continue without sharing additional data</DialogTitle>
          </DialogHeader>
          <DialogBody className={'gap-4'}>
            <Box>
              <Text>
                By continuing without sharing optional data, you can still
              </Text>
              <HBox className={'items-center gap-2'}>
                <CheckIcon className={'size-4'} />
                <Text>Collect points and redeem rewards when you visit</Text>
              </HBox>
            </Box>

            <Box>
              <Text>
                However, you may <span className={'font-bold'}>miss out</span>{' '}
                on the following benefits
              </Text>
              <HBox className={'items-center gap-2'}>
                <XIcon className={'size-6'} />
                <Text>
                  Access to unique deals and rewards given to members, eg.
                  rewards on your birthday.
                </Text>
              </HBox>
            </Box>
          </DialogBody>
          <DialogFooter>
            <Button
              variant={'outline'}
              onPress={() => {
                setCustomerConsent({
                  sellerId: seller.id,
                  didConsentShareProfile: didConsentShareProfile,
                  didConsentMarketing: didConsentMarketing,
                });
              }}
              isLoading={isSetCustomerConsentPending}>
              Continue Without Sharing
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PrivacyConsentPage;
