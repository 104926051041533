import React, { Suspense } from 'react';

import WaffleLoaderComponent from './WaffleLoaderComponent';

export const WaffleSuspenseBoundary = (props: {
  children: React.ReactNode;
  fallback?: React.ReactNode; // Choose to override the fallback
}) => {
  return <Suspense fallback={<WaffleLoaderComponent />} {...props} />;
};
