import { TriangleAlertIcon } from 'lucide-react';
import React from 'react';

import { Box } from './ui/box';
import { Text } from './ui/text';

export const WaffleErrorComponent = () => {
  return (
    <Box className={'text-muted-foreground m-auto items-center justify-center'}>
      <TriangleAlertIcon className={'mb-2 size-[24px]'} />
      <Text>We ran into an error, sorry about that!</Text>
      <Text>Please refresh the page and try again.</Text>
    </Box>
  );
};

export default WaffleErrorComponent;
