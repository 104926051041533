import _ from 'lodash';
import React from 'react';

import { OrderSummaryComponent } from '@waffle/components-web';
import { Card, CardContent, DialogBody, DialogContent } from '@waffle/ui-web';

import { useGetOrderQuery } from '../../../services/ordersService';

export const TransactionDialogContent = ({ orderId }: { orderId: string }) => {
  const { data: order } = useGetOrderQuery({
    orderId: orderId,
    expand: ['order.seller.logoImage', 'order.location'],
  });

  return (
    <DialogContent>
      <DialogBody className={'bg-gray-50'}>
        <Card className={'border-none bg-white shadow-lg'}>
          <CardContent className={'p-8'}>
            <OrderSummaryComponent
              order={order}
              seller={order.seller}
              sellerLogoImage={order.seller.logoImage}
              location={order.location}
            />
          </CardContent>
        </Card>
      </DialogBody>
    </DialogContent>
  );
};
