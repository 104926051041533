import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

import { Box, HBox, VerticalNav, VerticalNavButton } from '@waffle/ui-web';

export const AccountLayout = () => {
  const location = useLocation();
  return (
    <HBox className={'justify-center py-8'}>
      <Box className={'w-full max-w-[1024px] flex-col gap-8 lg:flex-row'}>
        <VerticalNav className={'h-fit w-[256px] md:sticky md:top-0'}>
          <Link to={'/account/profile'}>
            <VerticalNavButton
              isActive={location.pathname.startsWith('/account/profile')}>
              Profile
            </VerticalNavButton>
          </Link>
          <Link to={'/account/data-privacy'}>
            <VerticalNavButton
              isActive={location.pathname.startsWith('/account/data-privacy')}>
              Data Privacy
            </VerticalNavButton>
          </Link>
        </VerticalNav>

        <Box className={'flex-1'}>
          <Outlet />
        </Box>
      </Box>
    </HBox>
  );
};
