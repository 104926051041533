/**
 * Dialog component from shadcn/ui
 *
 * IMPORTANT!!!
 * This differs from shadcn/ui
 * 1. We implement our own DialogBody component that handles the scrollable main content of the Dialog.
 * 2. We don't render the close button by default. You can use <DialogCloseButton /> or <DialogFloatingCloseButton /> to render the close button.
 *
 * The structure of the Dialog should be:
 * <Dialog>
 *  <DialogTrigger />
 *  <DialogContent>
 *    <DialogHeader />
 *    <DialogBody />
 *    <DialogFooter />
 *  </DialogContent>
 * </Dialog>
 *
 * Edits
 * 1. Add DialogBody component that's scrollable (overflow-y-auto)
 * 2. Add w-[512px], max-w-full, max-h-full to DialogContent
 * 3. Remove sm:rounded-lg from DialogContent
 * 5. Add isOpen prop to Dialog
 * 6. Add shouldRenderDefaultCloseButton prop to DialogContent (default is true), giving the option to NOT render the default close button
 */
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { X } from 'lucide-react';
import * as React from 'react';

import { cn } from '../../lib/utils';

const Dialog = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Root> & {
    isOpen?: boolean;
    children: React.ReactNode;
  }
>(({ isOpen, children, ...props }, ref) => (
  <DialogPrimitive.Root open={isOpen} {...props}>
    {children}
  </DialogPrimitive.Root>
));
Dialog.displayName = DialogPrimitive.Root.displayName;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed inset-0 z-50 bg-black/80',
      className,
    )}
    {...props}
  />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    shouldRenderDefaultCloseButton?: boolean;
  }
>(
  (
    { shouldRenderDefaultCloseButton = true, className, children, ...props },
    ref,
  ) => (
    <DialogPortal>
      <DialogOverlay />

      <DialogPrimitive.Content
        ref={ref}
        className={cn(
          'bg-background data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] fixed left-[50%] top-[50%] z-50 flex max-h-full w-[512px] max-w-full translate-x-[-50%] translate-y-[-50%] flex-col overflow-hidden rounded-2xl border shadow-lg duration-200',
          className,
        )}
        {...props}>
        {shouldRenderDefaultCloseButton && <DialogFloatingCloseButton />}
        {children}
      </DialogPrimitive.Content>
    </DialogPortal>
  ),
);
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogFloatingCloseButton = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLButtonElement>) => {
  return (
    <DialogPrimitive.Close
      className={cn(
        'ring-offset-background focus:ring-ring data-[state=open]:bg-accent data-[state=open]:text-muted-foreground absolute right-4 top-4 z-50 rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none',
        className,
      )}
      {...props}>
      <X className={'size-4'} />
      <span className="sr-only">Close</span>
    </DialogPrimitive.Close>
  );
};

const DialogCloseButton = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLButtonElement>) => {
  return (
    <DialogPrimitive.Close
      className={cn(
        'ring-offset-background focus:ring-ring data-[state=open]:bg-accent data-[state=open]:text-muted-foreground bg-secondary rounded-sm p-2 opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none',
        className,
      )}
      {...props}>
      <X className={'size-4'} />
      <span className="sr-only">Close</span>
    </DialogPrimitive.Close>
  );
};

// Sticky header
const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn('bg-background flex flex-col p-4', className)}
    {...props}
  />
);
DialogHeader.displayName = 'DialogHeader';

const DialogBody = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn('bg-background flex flex-col overflow-y-auto p-4', className)}
    {...props}
  />
);
DialogBody.displayName = 'DialogBody';

// Sticky footer
const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'bg-background flex flex-col p-4 sm:flex-row sm:justify-end sm:space-x-2',
      className,
    )}
    {...props}
  />
);
DialogFooter.displayName = 'DialogFooter';

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn(
      'text-lg font-semibold leading-none tracking-tight',
      className,
    )}
    {...props}
  />
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn('text-muted-foreground p-4 text-sm', className)}
    {...props}
  />
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogClose,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
  DialogBody,
  DialogCloseButton,
  DialogFloatingCloseButton,
};
