import React from 'react';

import { Spinner } from './ui/spinner';

// Just wraps a spinner with a box that centers it
export const WaffleLoaderComponent = (): JSX.Element => {
  return <Spinner className={'m-auto'} />;
};

export default WaffleLoaderComponent;
