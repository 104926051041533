import _ from 'lodash';
import { DateTime } from 'luxon';

import { Common, Orders, Sellers } from '@waffle/common/src/models';
import { MoneyUtil } from '@waffle/common/src/util/money/MoneyUtil';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Box,
  Grid,
  HBox,
  Separator,
  Text,
} from '@waffle/ui-web';
import { StoreIcon } from '@waffle/ui-web/icons';

export const OrderSummaryComponent = ({
  order,
  seller,
  location,
  sellerLogoImage,
}: {
  order: Orders.SaleOrder;
  seller: Sellers.Seller;
  location: Sellers.Location;
  sellerLogoImage?: Common.Image;
}) => {
  return (
    <Box>
      {/* Header */}
      <HBox className={'items-end gap-2 py-3'}>
        <Box className={'flex-1 gap-2'}>
          <Avatar className={'h-[64px] w-[64px] rounded-lg'}>
            <AvatarImage src={sellerLogoImage?.thumbnailUrl} />
            <AvatarFallback>
              <StoreIcon className={'text-gray-500'} />
            </AvatarFallback>
          </Avatar>
          <Box>
            <Text variant={'h3'}>{seller.name}</Text>
            <Text variant={'muted'}>{location.address}</Text>
            <Text variant={'muted'}>{location.phoneNumber}</Text>
          </Box>
        </Box>
        <Box className={'gap-2'}>
          <Box>
            <Text variant={'label-muted'} className={'text-right'}>
              You spent
            </Text>
            <Text variant={'h1'} className={'text-right'}>
              {MoneyUtil.formatCurrency({
                amount: order.totalAmount,
                currencyCode: seller.defaultCurrencyCode,
                currencyDisplay: 'none',
              })}
            </Text>
          </Box>
          {order.returns.length > 0 && (
            <Box>
              <Text variant={'label-muted'} className={'text-right'}>
                Refunded
              </Text>
              <Text variant={'h1'} className={'text-right'}>
                {MoneyUtil.formatCurrency({
                  amount: _.sumBy(
                    order.returns,
                    (returnOrder) => returnOrder.totalAmount,
                  ),
                  currencyCode: seller.defaultCurrencyCode,
                  currencyDisplay: 'none',
                })}
              </Text>
            </Box>
          )}
        </Box>
      </HBox>

      <Separator />

      <Grid className={'grid-cols-2 py-3'}>
        <Text variant={'muted'}>Date</Text>
        <Text variant={'label'}>
          {DateTime.fromISO(order.createdAt).toLocaleString(
            DateTime.DATETIME_MED,
          )}
        </Text>
        <Text variant={'muted'}>Reference ID</Text>
        <Text variant={'label'}>{order.referenceId}</Text>
        {!!order.orderTypeName && (
          <>
            <Text variant={'muted'}>Order Type</Text>
            <Text variant={'label'}>{order.orderTypeName}</Text>
          </>
        )}
        {!!order.ticketValue && (
          <>
            <Text variant={'muted'}>Ticket</Text>
            <Text variant={'label'}>{order.ticketValue}</Text>
          </>
        )}
      </Grid>

      <Separator />

      <Box className={'py-3'}>
        <Grid className={'grid-cols-12'}>
          <Text variant={'label-muted'} className={'col-span-1'}>
            Qty
          </Text>
          <Text variant={'label-muted'} className={'col-span-7'}>
            Item
          </Text>
          <Text variant={'label-muted'} className={'col-span-2 text-right'}>
            Price
          </Text>
          <Text variant={'label-muted'} className={'col-span-2 text-right'}>
            Total
          </Text>
        </Grid>
        {order.lineItems.map((orderLineItem) => (
          <Grid className={'grid-cols-12 py-1'}>
            <Text variant={'label'} className={'col-span-1'}>
              {orderLineItem.quantity}
            </Text>
            <Text variant={'label'} className={'col-span-7'}>
              {orderLineItem.name}
            </Text>
            <Text variant={'label'} className={'col-span-2 text-right'}>
              {MoneyUtil.formatCurrency({
                amount: orderLineItem.price,
                currencyCode: seller.defaultCurrencyCode,
                currencyDisplay: 'none',
              })}
            </Text>
            <Text variant={'label'} className={'col-span-2 text-right'}>
              {MoneyUtil.formatCurrency({
                amount: orderLineItem.quantityPrice,
                currencyCode: seller.defaultCurrencyCode,
                currencyDisplay: 'none',
              })}
            </Text>
            {orderLineItem.addOns.map((addOn) => (
              <>
                <Text variant={'muted'} className={'col-span-1 col-start-2'}>
                  {addOn.quantity}
                </Text>
                <Text variant={'muted'} className={'col-span-6'}>
                  {addOn.name}
                </Text>
                <Text variant={'muted'} className={'col-span-2 text-right'}>
                  {MoneyUtil.formatCurrency({
                    amount: addOn.price,
                    currencyCode: seller.defaultCurrencyCode,
                    currencyDisplay: 'none',
                  })}
                </Text>
                <Text variant={'muted'} className={'col-span-2 text-right'}>
                  {MoneyUtil.formatCurrency({
                    amount: addOn.totalAmount,
                    currencyCode: seller.defaultCurrencyCode,
                    currencyDisplay: 'none',
                  })}
                </Text>
              </>
            ))}
            {orderLineItem.discounts.map((discount) => (
              <>
                <Text variant={'muted'} className={'col-span-1 col-start-2'}>
                  {discount.name}
                </Text>
                <Text variant={'muted'} className={'col-span-6 col-start-12'}>
                  {MoneyUtil.formatCurrency({
                    amount: discount.amount,
                    currencyCode: seller.defaultCurrencyCode,
                    currencyDisplay: 'none',
                  })}
                </Text>
              </>
            ))}
            {!!orderLineItem.note && (
              <>
                <Text variant={'muted'} className={'col-span-6 col-start-2'}>
                  Note: {orderLineItem.note}
                </Text>
              </>
            )}
          </Grid>
        ))}
      </Box>
      <Separator />
      <Grid className={'grid-cols-12 py-3'}>
        <Text variant={'label'} className={'col-span-6'}>
          Subtotal
        </Text>
        <Text variant={'label'} className={'col-span-6 text-right'}>
          {MoneyUtil.formatCurrency({
            amount: order.subtotalAmount,
            currencyCode: seller.defaultCurrencyCode,
            currencyDisplay: 'none',
          })}
        </Text>
        {order.extraCharges.map((extraCharge) => (
          <>
            <Text variant={'muted'} className={'col-span-6'}>
              {extraCharge.name}
            </Text>
            <Text variant={'muted'} className={'col-span-6 text-right'}>
              {MoneyUtil.formatCurrency({
                amount: extraCharge.amount,
                currencyCode: seller.defaultCurrencyCode,
                currencyDisplay: 'none',
              })}
            </Text>
          </>
        ))}
        {order.taxes.map((tax) => (
          <>
            <Text variant={'muted'} className={'col-span-6'}>
              {`${tax.name}${tax.isLineItemPricingInclusive ? ' (Incl)' : ''}`}
            </Text>
            <Text variant={'muted'} className={'col-span-6 text-right'}>
              {MoneyUtil.formatCurrency({
                amount: tax.amount,
                currencyCode: seller.defaultCurrencyCode,
                currencyDisplay: 'none',
              })}
            </Text>
          </>
        ))}
        {!!order.cashRoundingAmount && (
          <>
            <Text variant={'muted'} className={'col-span-6'}>
              Cash Rounding
            </Text>
            <Text variant={'muted'} className={'col-span-6 text-right'}>
              {MoneyUtil.formatCurrency({
                amount: order.cashRoundingAmount,
                currencyCode: seller.defaultCurrencyCode,
                currencyDisplay: 'none',
              })}
            </Text>
          </>
        )}
      </Grid>
      <Separator />
      <Grid className={'grid-cols-12 py-3'}>
        <>
          <Text variant={'h3'} className={'col-span-6'}>
            Total
          </Text>
          <Text variant={'h3'} className={'col-span-6 text-right'}>
            {MoneyUtil.formatCurrency({
              amount: order.totalAmount,
              currencyCode: seller.defaultCurrencyCode,
              currencyDisplay: 'none',
            })}
          </Text>
        </>

        {order.tenders.map((orderTender) => (
          <>
            <Text variant={'label'} className={'col-span-6 text-right'}>
              {orderTender.name}
            </Text>

            <Text variant={'label'} className={'col-span-6 text-right'}>
              {MoneyUtil.formatCurrency({
                amount: orderTender.amount,
                currencyCode: seller.defaultCurrencyCode,
                currencyDisplay: 'none',
              })}
            </Text>
          </>
        ))}
      </Grid>
    </Box>
  );
};
