import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import WaffleErrorComponent from './WaffleErrorComponent';

export const WaffleErrorBoundary = (props: {
  children: React.ReactNode;
  fallback?: React.ReactNode; // Choose to override the default Error component
}) => {
  return <ErrorBoundary fallback={<WaffleErrorComponent />} {...props} />;
};
