import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import React from 'react';

import { Buyers, Customers } from '@waffle/common/src/models';
import {
  Box,
  Card,
  CardContent,
  ExternalLink,
  Grid,
  Label,
  RadioGroup,
  RadioGroupItem,
  Separator,
  Spinner,
  Switch,
  Text,
} from '@waffle/ui-web';

import { useBuyerUserQuery } from '../../../services/buyersService';
import { useCustomerQuery } from '../../../services/customersService';
import { useSubdomainSellerQuery } from '../../../services/sellersService';
import buyersApiClient from '../../../utils/ApiService';

export const DataPrivacyPage = () => {
  const queryClient = useQueryClient();
  const { data: seller } = useSubdomainSellerQuery();
  const { data: buyerUser } = useBuyerUserQuery();
  const { data: customer } = useCustomerQuery();

  const {
    mutate: setPreferredCommunicationsChannel,
    isPending: isSetPreferredCommunicationsChannelPending,
  } = useMutation({
    mutationFn: async (communicationsChannel: Buyers.CommunicationsChannel) => {
      await buyersApiClient.request({
        method: 'PUT',
        url: `/buyer_users/${buyerUser?.id}`,
        data: {
          buyerUser: {
            preferredCommunicationsChannel: communicationsChannel,
          },
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/buyer_users'] });
    },
  });

  const {
    mutate: toggleCustomerMarketingConsent,
    isPending: isToggleCustomerMarketingConsentPending,
  } = useMutation({
    mutationFn: async ({ customer }: { customer: Customers.Customer }) => {
      await buyersApiClient.request({
        method: 'POST',
        url: `/customers/${customer.id}/set_marketing_consent`,
        data: {
          didConsentMarketing: !customer.didConsentMarketing,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/customers'] });
    },
  });

  const {
    mutate: toggleCustomerShareProfileConsent,
    isPending: isToggleCustomerShareProfileConsentPending,
  } = useMutation({
    mutationFn: async ({ customer }: { customer: Customers.Customer }) => {
      await buyersApiClient.request({
        method: 'POST',
        url: `/customers/${customer.id}/set_share_profile_consent`,
        data: {
          didConsentShareProfile: !customer.didConsentShareProfile,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/customers'] });
    },
  });

  return (
    <Box>
      <Box className={'mb-8'}>
        <Text variant={'h2'} className={'mb-4'}>
          Data Privacy Settings
        </Text>

        <Box className={'gap-4'}>
          <Card>
            <CardContent>
              <Grid className={'grid-cols-2 gap-8'}>
                <Box>
                  <Text variant={'label'}>Share Required Data</Text>
                  <Text variant={'muted'}>
                    Required data such as your mobile number and email address
                    will always be shared with {seller.name} so that they can
                    provide you with better services. You will never receive
                    unsolicited marketing communications.
                  </Text>
                </Box>

                <Box minWidth={'64px'} alignItems={'flex-end'}>
                  {/*This can never be un-toggled because Buyer will always have to share their email address and mobile number to continue using Waffle's services*/}
                  <Switch isDisabled={true} isChecked={true} />
                </Box>
                <Box>
                  <Text variant={'label'}>Share Additional Data</Text>
                  <Text variant={'muted'}>
                    {`Additional data, such as your name and birthday, helps ${seller.name} provide you with more personalised services and rewards.`}
                  </Text>
                </Box>
                <Box minWidth={'64px'} alignItems={'flex-end'}>
                  {isToggleCustomerShareProfileConsentPending ? (
                    <Spinner />
                  ) : (
                    <Switch
                      isChecked={!!customer.didConsentShareProfile}
                      onCheckedChange={() => {
                        toggleCustomerShareProfileConsent({
                          customer: customer,
                        });
                      }}
                    />
                  )}
                </Box>
              </Grid>
            </CardContent>

            <Separator />

            <CardContent>
              <Grid className={'grid-cols-2 gap-8'}>
                <Box>
                  <Text variant={'label'}>
                    Receive marketing communications from{' '}
                    <span className={'font-semibold'}>{seller.name}</span>
                  </Text>
                  <Text variant={'muted'}>
                    {seller.name} will never send you unsolicited marketing
                    communications, unless you explicitly allow it.
                  </Text>
                </Box>

                <Box>
                  {isToggleCustomerMarketingConsentPending ? (
                    <Spinner />
                  ) : (
                    <Switch
                      isChecked={customer.didConsentMarketing}
                      onCheckedChange={() => {
                        toggleCustomerMarketingConsent({ customer: customer });
                      }}
                    />
                  )}
                </Box>
              </Grid>
            </CardContent>

            <Separator />

            <CardContent>
              <Grid className={'grid-cols-2 gap-8'}>
                <Box>
                  <Text variant={'label'}>
                    Preferred communications channel
                  </Text>
                  <Text variant={'muted'}>
                    Marketing communications and notifications will be sent via
                    your preferred channel, where possible.
                  </Text>
                </Box>

                <Box>
                  <RadioGroup
                    value={buyerUser.preferredCommunicationsChannel}
                    onValueChange={(value) =>
                      setPreferredCommunicationsChannel(
                        value as Buyers.CommunicationsChannel,
                      )
                    }
                    className="space-y-2">
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem
                        value={Buyers.CommunicationsChannel.SMS}
                        id="sms"
                      />
                      <Label htmlFor="sms">SMS</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem
                        value={Buyers.CommunicationsChannel.EMAIL}
                        id="email"
                      />
                      <Label htmlFor="email">Email</Label>
                    </div>
                  </RadioGroup>
                </Box>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Box>

      <PrivacyPolicySection />
    </Box>
  );
};

export const PrivacyPolicySection = () => {
  const { data: seller } = useSubdomainSellerQuery();
  const { data: buyerUser } = useBuyerUserQuery();
  const { data: customer } = useCustomerQuery();

  return (
    <Box>
      <Box className={'mb-4'}>
        <Text variant={'h2'}>Privacy Policy</Text>
        <Text variant={'muted'}>
          Important information about how we may use your data
        </Text>
      </Box>

      <Card>
        <CardContent>
          <Grid className={'grid-cols-2 gap-8'}>
            <Box>
              <Text variant={'label'}>Waffle Platform</Text>
            </Box>
            <Box>
              <Text variant={'link'}>
                <ExternalLink
                  shouldOpenInNewTab={true}
                  shouldIncludeIcon={true}
                  href={
                    'https://www.wafflepos.com/legal/waffle-buyer-privacy-policy'
                  }>
                  Privacy Policy
                </ExternalLink>
              </Text>
              {!!buyerUser.acceptedWafflePrivacyPolicyAt ? (
                <Text variant={'muted'}>
                  Accepted on{' '}
                  {DateTime.fromISO(
                    buyerUser.acceptedWafflePrivacyPolicyAt,
                  ).toLocaleString(DateTime.DATE_MED)}
                </Text>
              ) : null}
            </Box>
          </Grid>
        </CardContent>
        <Separator />
        <CardContent>
          <Grid className={'grid-cols-2 gap-8'}>
            <Box>
              <Text variant={'label'}>{seller.name}</Text>
            </Box>
            <Box>
              <Text variant={'link'}>
                <ExternalLink
                  shouldOpenInNewTab={true}
                  shouldIncludeIcon={true}
                  href={
                    seller.latestSellerPrivacyPolicy?.file?.url ??
                    `https://www.wafflepos.com/legal/seller-buyer-privacy-policy?seller=${seller.slug}`
                  }>
                  Privacy Policy
                </ExternalLink>
              </Text>
              {!!customer.acceptedSellerPrivacyPolicyAt ? (
                <Text variant={'muted'}>
                  Accepted on{' '}
                  {DateTime.fromISO(
                    customer.acceptedSellerPrivacyPolicyAt,
                  ).toLocaleString(DateTime.DATE_MED)}
                </Text>
              ) : null}
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};
