import React, { useEffect } from 'react';
import {
  Navigate,
  Outlet,
  Route,
  createRoutesFromElements,
  useNavigate,
} from 'react-router-dom';

import { AppLayout } from './pages/AppLayout';
import { DashboardLayout } from './pages/app/DashboardLayout';
import { AccountLayout } from './pages/app/account/AccountLayout';
import { DataPrivacyPage } from './pages/app/account/DataPrivacyPage';
import { ProfilePage } from './pages/app/account/ProfilePage';
import { InventoryRewardDialog } from './pages/app/rewards/InventoryRewardDialog';
import { RewardsActivityPage } from './pages/app/rewards/RewardsActivityPage';
import { RewardsPage } from './pages/app/rewards/RewardsPage';
import { ShopRewardDialog } from './pages/app/rewards/ShopRewardDialog';
import { TransactionsPage } from './pages/app/transactions/TransactionsPage';
import { AuthLoginPage } from './pages/auth/AuthLoginPage';
import { useIsBuyerUserProfileIncomplete } from './services/buyersService';
import { useAuth } from './utils/useAuth';

export const RedirectToLoginIfUnauthenticated = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { isAuthenticated } = useAuth();
  if (!isAuthenticated) {
    return <Navigate to={'/auth/login'} replace />;
  }
  return children;
};

export const RedirectToDashboardIfAuthenticated = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { isAuthenticated } = useAuth();
  if (isAuthenticated) {
    return <Navigate to={'/'} replace />;
  }
  return children;
};

/**
 * Only redirect to profile page if profile is incomplete on first load
 */
export const RedirectToProfilePageIfProfileIncomplete = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const isProfileIncomplete: boolean = useIsBuyerUserProfileIncomplete();

  useEffect(() => {
    if (isProfileIncomplete) {
      navigate('/account/profile');
    }
  }, []); // Don't add dependencies to avoid infinite re-renders

  return children;
};

export const routes = createRoutesFromElements(
  <Route element={<AppLayout />}>
    <Route
      path={'/'}
      element={
        <RedirectToLoginIfUnauthenticated>
          <DashboardLayout />
        </RedirectToLoginIfUnauthenticated>
      }>
      <Route
        element={
          <RedirectToProfilePageIfProfileIncomplete>
            <Outlet />
          </RedirectToProfilePageIfProfileIncomplete>
        }>
        <Route index element={<Navigate to="/rewards" replace />} />
        <Route path={'/rewards'}>
          <Route index element={<RewardsPage />} />

          <Route
            path={'/rewards/inventory/:issuedRewardId'}
            element={
              <>
                <RewardsPage />
                <InventoryRewardDialog />
              </>
            }
          />
          <Route
            path={'/rewards/shop/:rewardsListingId'}
            element={
              <>
                <RewardsPage />
                <ShopRewardDialog />
              </>
            }
          />

          <Route path={'/rewards/activity'} element={<RewardsActivityPage />} />
        </Route>

        <Route path={'/transactions'} element={<TransactionsPage />} />

        <Route path={'/account'} element={<AccountLayout />}>
          <Route index element={<Navigate to={'/account/profile'} />} />
          <Route path={'/account/profile'} element={<ProfilePage />} />
          <Route path={'/account/data-privacy'} element={<DataPrivacyPage />} />
        </Route>
      </Route>
    </Route>
    <Route
      path={'/auth/login'}
      element={
        <RedirectToDashboardIfAuthenticated>
          <AuthLoginPage />
        </RedirectToDashboardIfAuthenticated>
      }
    />
  </Route>,
);
