import { useMemo } from 'react';

import { Rewards, Sellers } from '@waffle/common/src/models';
import { Box, HBox, Text, cn } from '@waffle/ui-web';

export const RewardsCard = ({
  seller,
  rewardsProgramme,
  rewardsMembership,
  className,
}: {
  seller: Sellers.Seller;
  rewardsProgramme: Rewards.RewardsProgramme;
  rewardsMembership: Rewards.RewardsMembership;
  className?: string;
}) => {
  const currentRewardsTier: Rewards.RewardsTier | undefined = useMemo(() => {
    return rewardsMembership.rewardsTier;
  }, [rewardsMembership]);
  // Undefined if the user is at the highest tier
  const nextRewardsTier: Rewards.RewardsTier | undefined = useMemo(() => {
    const currentRewardsTierIndex = rewardsProgramme.rewardsTiers.findIndex(
      (rewardsTier: Rewards.RewardsTier) =>
        rewardsTier.id === currentRewardsTier.id,
    );
    if (currentRewardsTierIndex === rewardsProgramme.rewardsTiers.length - 1) {
      return undefined; // There is no next tier because the user is at the highest tier
    }
    return rewardsProgramme.rewardsTiers[currentRewardsTierIndex + 1];
  }, [rewardsProgramme, currentRewardsTier]);

  return (
    <Box
      className={cn(
        'aspect-[1.586] w-full justify-between rounded-xl p-4 shadow-md',
        className,
      )} // Aspect ratio follows standard credit card measurements of 3.37 inch x 2.125 inch
      style={{
        backgroundColor: currentRewardsTier.cardBackgroundColor,
        backgroundImage: `url(${currentRewardsTier.cardBackgroundImage?.url})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
      {/* Seller name */}
      <HBox className={'items-center justify-center'}>
        <Box
          className={
            'items-center justify-center rounded-full bg-white/20 px-2'
          }>
          <Text
            variant={'label'}
            style={{
              color: currentRewardsTier.cardFontColor,
            }}>
            {seller.name}
          </Text>
        </Box>
      </HBox>

      <HBox className={'items-baseline gap-1'}>
        <Text
          className={'text-4xl font-semibold'}
          style={{ color: currentRewardsTier.cardFontColor }}>
          {rewardsMembership.currentPoints}{' '}
        </Text>
        <Text
          variant={'label'}
          style={{
            color: currentRewardsTier.cardFontColor,
          }}>
          {rewardsProgramme.pointName}
        </Text>
      </HBox>

      <Box>
        {/*  Tier Name */}
        <Box>
          <Text
            variant={'label'}
            style={{ color: currentRewardsTier.cardFontColor }}>
            {currentRewardsTier.tierName}
          </Text>
        </Box>

        {/* Progress Bar */}
        <Box
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}
          className={'h-1 w-full rounded-full'}>
          <Box
            className={'h-full rounded-full'}
            style={{
              backgroundColor: currentRewardsTier.cardFontColor,
              width: !nextRewardsTier
                ? '100%'
                : `${
                    (rewardsMembership.cumulativePoints /
                      nextRewardsTier.pointsRequired) *
                    100
                  }%`,
            }}
          />
        </Box>
        <Box>
          <Text
            className={'text-xs'}
            style={{ color: currentRewardsTier.cardFontColor }}>
            {!nextRewardsTier
              ? 'You are at the highest tier'
              : `${
                  nextRewardsTier.pointsRequired -
                  rewardsMembership.cumulativePoints
                } more ${rewardsProgramme.pointName} to the next tier`}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
