import { useNavigate, useParams } from 'react-router-dom';

import { Listings, Rewards } from '@waffle/common/src/models';
import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  HBox,
  Separator,
  Text,
} from '@waffle/ui-web';
import {
  ClockIcon,
  DollarSignIcon,
  PercentIcon,
  ShoppingBasketIcon,
  StoreIcon,
} from '@waffle/ui-web/icons';

import {
  useIssuedRewardsQuery,
  useRewardsProgrammeQuery,
} from '../../../services/rewardsService';
import { useSubdomainSellerQuery } from '../../../services/sellersService';

export const InventoryRewardDialog = () => {
  const navigate = useNavigate();
  const { issuedRewardId } = useParams();
  const { data: seller } = useSubdomainSellerQuery();
  const { data: rewardsProgramme } = useRewardsProgrammeQuery();

  // TODO: Implement a proper endpoint to get 1 issued reward
  const { data: issuedRewards } = useIssuedRewardsQuery();
  const issuedReward = issuedRewards?.find(
    (issuedReward) => issuedReward.id === issuedRewardId,
  );

  // Temp hack to prevent the dialog from showing if the issued reward is not loaded from cache
  if (!issuedReward) {
    return null;
  }

  return (
    <Dialog open={true} onOpenChange={() => navigate('/rewards')}>
      <DialogContent>
        {/* Paper UI */}
        <DialogBody className={'bg-gray-100'}>
          <Box className={'drop-shadow-xl'}>
            <Box
              className={'rounded-lg bg-white'}
              style={{
                WebkitMaskImage: `radial-gradient(circle at right 10px bottom 80px, transparent 10px, red 10.5px)`,
                WebkitMaskPosition: 10,
              }}>
              <Box className={'gap-8 p-4'}>
                {/* Header */}
                <Box>
                  <HBox className={'items-end gap-4'}>
                    {/* Icon / Image */}
                    <Box
                      className={
                        'h-[80px] w-[80px] items-center justify-center rounded-lg shadow-md'
                      }>
                      {issuedReward.listingSnapshot.rewardsListingType ===
                      Rewards.ListingType.ITEM ? (
                        <ShoppingBasketIcon className={'text-gray-500'} />
                      ) : (
                        <PercentIcon className={'text-gray-500'} />
                      )}
                    </Box>
                    <Box className={'items-start'}>
                      <Badge>
                        {issuedReward.listingSnapshot.rewardsListingType ===
                        Listings.ListingType.ITEM
                          ? 'Item'
                          : 'Discount'}
                      </Badge>
                      <Text className={'text-md line-clamp-1 font-semibold'}>
                        {issuedReward.listingSnapshot.name}
                      </Text>
                      <Text variant={'muted'}>
                        {issuedReward.listingSnapshot.description}
                      </Text>
                    </Box>
                  </HBox>
                </Box>

                {/* Issuance Details */}
                <Box className={'gap-4'}>
                  <HBox className={'gap-2'}>
                    <DollarSignIcon />
                    <Text variant={'label'}>
                      {issuedReward.listingSnapshot.pointsRequired}{' '}
                      {rewardsProgramme.pointName}
                    </Text>
                  </HBox>
                  <HBox className={'gap-2'}>
                    <ClockIcon />
                    <Text variant={'label'}>Does not expire</Text>
                  </HBox>
                  <HBox className={'gap-2'}>
                    <StoreIcon />
                    {issuedReward.listingSnapshot.presentAtAllLocations ? (
                      <Box>
                        <Text variant={'label'}>
                          Redeemable at all {seller.name} store(s)
                        </Text>
                      </Box>
                    ) : (
                      <Box>
                        <Text variant={'label'}>
                          Redeemable at{' '}
                          {issuedReward.listingSnapshot.sellerLocations.length}{' '}
                          {seller.name} store(s)
                        </Text>
                        <ul>
                          {issuedReward.listingSnapshot.sellerLocations.map(
                            (location) => {
                              return (
                                <li key={location.id}>
                                  <Text variant={'muted'}>
                                    • {location.name}
                                  </Text>
                                </li>
                              );
                            },
                          )}
                        </ul>
                      </Box>
                    )}
                  </HBox>
                </Box>
              </Box>

              <Separator className={'border-dashed'} />

              {/* Redeem in-store */}
              {/* FUTURE: If we have promo codes or barcode or other forms of redemption, it can come here */}
              <Box className={'h-[80px] items-center justify-center p-4'}>
                <Button
                  className={'w-full'}
                  variant={'secondary'}
                  isDisabled={true}>
                  Redeem in-store
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogBody>
      </DialogContent>
    </Dialog>
  );
};
